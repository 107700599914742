@import url('https://fonts.googleapis.com/css?family=Quicksand');
@import url('https://fonts.googleapis.com/css?family=Poppins');

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #727070;
}

a {
    text-decoration: none;
    cursor: pointer;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

a:hover {
    text-decoration: none;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

a,
p,
li,
td,
span {
    font-size: 14.5px;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
th {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
}

.link-btn {
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    padding: 6px 15px;
    border-radius: 2px;
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: #fff !important;
    /* font-family: Arial;
    */
    font-size: 13px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    background: #f4364f;
}

.link-btn:hover {
    background-color: #f4364f;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    color: #fff;
}

.div {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.div:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.com-colo-abou p,
li {
    color: #888;
}

.inn-page-bg {
    position: relative;
    overflow: hidden;
    background: #f3f3f3;
}

.inn-page-con-bg {
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 0px -2px 7px rgba(150, 150, 150, 0.4);
}

::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: #6b7a88;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #6b7a88;
    opacity: 1;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #6b7a88;
    opacity: 1;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #909;
}

/*-------------------------------------------------------*/
/* 2.HEADER(LOG0 & HELP LINE) 
/*-------------------------------------------------------*/
.head {
    background: #ffffff;
    padding: 15px 0px;
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    -ms-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
}

.head_left {
    padding-left: 0px !important;
}

.affix {
    position: fixed;
    width: 100%;
    z-index: 999;
    box-shadow: 0px 0px 18px -3px rgba(0, 0, 0, 0.43);
    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    -ms-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
}

.head_right_all {
    padding-right: 1px !important;
}

.head_right ul {
    padding: 0px;
    margin: 0 auto;
    /* display: table;
    */
    float: right;
}

.head_right ul li {
    display: inline-block;
    vertical-align: bottom;
}

.head_right ul li a {
    font-size: 13px;
    font-weight: 600;
    text-decoration: none;
    font-family: 'Quicksand', sans-serif;
}

.head_right ul li:first-child a {
    padding-right: 7px;
    color: #5e6469;
}

.head_right ul li:nth-child(2) a {
    padding-right: 7px;
    color: #5e6469;
}

.head_right ul li a .fa-facebook {
    background: #3b5998;
}

.head_right ul li a .fa-google-plus {
    background: #f4364f;
}

.head_right ul li a .fa-twitter {
    background: #55acee;
}

.head_right ul li a .fa-linkedin {
    background: #0077b5;
}

.head_right ul li a .fa-whatsapp {
    background: #3ead19;
}

.spe-title {
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
}

.spe-title h2 {
    margin-bottom: 0px;
    padding-bottom: 20px;
    margin-top: 0px;
    text-transform: capitalize;
    font-size: 44px;
    color: #253d52;
    /* text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.59);
    */
}

.spe-title h2 span {
    color: #f4364f;
    font-size: 44px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.spe-title p {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 400;
    padding-top: 12px;
    color: #888;
    font-family: 'Quicksand', sans-serif;
}

.spe-title-1 h2 {
    color: #253d52;
}

.spe-title-1 p {
    color: #4e5963;
}

.title-line {
    margin-top: -10px;
}

.tl-1 {
    width: 80px;
    height: 1px;
    display: inline-block;
    background: #eee;
}

.tl-2 {
    display: inline-block;
    height: 12px;
    margin: 0 5px;
    position: relative;
    top: 5px;
    width: 12px;
    border: 1px solid #ebc131;
    border-radius: 50px;
}

.tl-3 {
    width: 80px;
    height: 1px;
    display: inline-block;
    background: #eee;
}

/*-------------------------------------------------------*/
/* 3.NAVIGATION MENU(NOTE:OTHER NAVIGATION CSS FILES FIND ON BOOTSTRAP.CSS ) 
/*-------------------------------------------------------*/
.menu_book {
    float: right;
    position: relative;
    overflow: hidden;
    margin-top: 10px;
}

.nav>li>a {
    font-weight: 600;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
}

.dropdown-menu>li>a {
    font-weight: 500;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
}

.menu_book a {
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 6px 15px;
    border-radius: 2px;
    display: inline-block;
    background: #1aa5d8;
    background: linear-gradient(to bottom, #1aa5d8, #1386b1);
    font-family: 'Quicksand', sans-serif;
}

.menu_book a:hover {
    color: #fff;
    background: #4cb2db;
    border-radius: 2px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}

.dropdown-menu>li>a {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.dropdown-menu>li>a:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    background-color: #1aa5d8;
    cursor: pointer;
    padding-left: 25px;
}

/*-------------------------------------------------------*/
/* 4.SLIDER & INNER PAGE BANNER 
/*-------------------------------------------------------*/
section.c3 {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center;
    background-size: cover;
}

.carousel-inner>.item>img,
.carousel-inner>.item>a>img {
    float: right;
}

.slider-cap {
    font-family: 'Montserrat', sans-serif;
}

.slider-cap h1 {
    font-size: 40px;
    /* text-transform: uppercase;
    */
    font-weight: 700;
}

.slider-cap p {
    font-size: 20px;
    font-weight: 400;
    /* font-family: 'Raleway', sans-serif;
    */
    padding-bottom: 30px;
    padding-top: 15px;
    line-height: 32px;
    color: #fff;
}

.slider-cap a {
    text-decoration: none;
    color: #FFF;
    padding: 8px 15px 10px 15px;
    background: #1aa5d8;
    border-radius: 50px;
    font-weight: 700;
    /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    */
    /* text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.47);
    */
    font-size: 16px;
}

.slider-cap a:hover {
    transition: all 0.5s ease;
    background: #1aa5d8;
    color: #000;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0);
}

.banner {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat;
    background-size: cover;
    padding: 100px 0px;
    position: relative;
}

.banner:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(76, 97, 175, 0.34);
}

.home_enq {
    background: #253d52;
}

.tp-simpleresponsive a {
    text-decoration: none;
    color: #FFF;
    padding: 5px 10px;
    background: #03a9f4;
    border-radius: 50px;
    margin-left: 25px;
    font-weight: 700;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
}

.tp-simpleresponsive a:hover {
    text-decoration: none;
    background: #E7AF06;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    color: #F3F3F3;
}

.tb-space {
    padding: 100px 0px;
}

.tb-space-inn {
    padding: 40px 0px;
}

/*-------------------------------------------------------*/
/* 5.SLIDER ENQUIRY 
/*-------------------------------------------------------*/
.home_form form ul {
    padding: 8px 8px 4px 8px;
    margin-bottom: 0px;
    position: relative;
    overflow: hidden;
}

.home_form form ul li {
    display: inline-block;
    line-height: 10px;
    padding: 5px 0px;
}

.home_form form ul li:nth-child(2) input {
    position: relative;
}

.home_form form ul li h4 {
    color: #fff;
    text-transform: uppercase;
    margin-top: 8px;
}

.home_form form ul li input {
    border: 0px;
    padding: 8px;
    border-radius: 2px;
    width: 95%;
}

.home_form form ul li textarea {
    border: 0px;
    padding: 7px;
    border-radius: 2px;
    resize: none;
}

.home_form form ul li input[type="submit"] {
    background: #1aa5d8;
    line-height: 13px;
    font-weight: 600;
    padding-top: 11px;
    color: #ffffff;
    /* text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.62);
    */
    text-align: center;
}

/*-------------------------------------------------------*/
/* 6.TOUR PLACES 
/*-------------------------------------------------------*/
.b_pack {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    -webkit-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.32);
    box-shadow: 0px 2px 5px -1px rgba(0, 0, 0, 0.32);
    o-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    margin-bottom: 30px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.b_pack h4 {
    font-size: 14px;
    text-transform: uppercase;
    margin: 15px 0;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.b_pack h4 a {
    color: #000000;
    text-decoration: none;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}

.v_pl_name {
    text-transform: capitalize;
    padding: 5px;
    color: #6C6C6C;
    font-weight: 500;
}

.v_place_img {
    position: relative;
    overflow: hidden;
}

.v_place_img img {
    width: 100% !important;
}

.b_packages img {
    width: 100%;
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
}

.b_packages img:hover {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
}

.band {
    width: 50px;
    height: auto;
    position: absolute;
    z-index: 9;
    left: 13px;
    top: -6px;
}

.band img:hover {
    -webkit-transform: scale(01.0);
    transform: scale(01.0);
}

.home_title h1 {
    text-align: center;
    color: #424e57;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 36px;
    margin-top: 0px;
}

.home_title h1 span {
    color: #00BCD4;
}

.home_title p {
    color: #707275;
    /* margin-bottom: 30px;
    */
    text-align: center;
}

.pack_icon ul {
    padding: 0px;
    padding-top: 15px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
}

.pack_icon ul li {
    display: inline-block;
    width: 25%;
    float: left;
}

.pack_icon_2 img {
    width: 22px;
}

.home_all {
    background: #1aa5d8;
    border-bottom: 2px solid #e3aa00;
    border-radius: 3px;
    margin: 0 auto;
    display: table;
    text-decoration: none;
    color: #000;
    padding: 5px 10px;
    /* margin-bottom: 35px;
    */
}

.h_con {
    background: #1aa5d8;
    position: relative;
    overflow: hidden;
}

.home_consul_1 {
    padding-right: 0px;
    padding-left: 0px;
}

.home_consul_1 img {
    width: 100%;
}

.home_consul {
    overflow: hidden;
    padding: 0px 50px;
}

.home_consul h1 {
    font-size: 42px;
    padding: 5px 0px 25px 0px;
}

.home_consul h4 {
    font-size: 34px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px 0px 25px 0px;
}

.home_consul p {
    line-height: 35px;
}

.home_consul a {
    background: #ffffff;
    border-bottom: 2px solid #d6a204;
    border-radius: 3px;
    /* margin: 0 auto;
    */
    display: table;
    text-decoration: none;
    color: #000;
    padding: 5px 10px;
    margin-top: 40px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.tout-map iframe {
    height: 300px;
    width: 100%;
    border: 0px;
}

/*-------------------------------------------------------*/
/* 6.1.HOTELS 
/*-------------------------------------------------------*/
.to-ho-hotel-con {
    position: relative;
    overflow: hidden;
    border: 1px solid #dfdfdf;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.09);
    margin-bottom: 30px;
}

.to-ho-hotel-con-1 {
    position: relative;
}

.to-ho-hotel-con-1:before {
    content: '';
    position: absolute;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.72) 14%, rgba(0, 0, 0, 0.08) 35%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.to-ho-hotel-con-1 img {
    width: 100%;
}

.to-ho-hotel-con-23 {
    position: relative;
    overflow: hidden;
    padding: 20px;
    background: linear-gradient(-45deg, #fff, #a4a4a4, #ffcfbe, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.to-ho-hotel-con-2 h4 {
    margin-top: 0px;
    padding-bottom: 10px;
    margin-bottom: 0px;
}

.to-ho-hotel-con-2 a {
    color: #4d90fe;
}

.to-ho-hotel-con-3 ul {
    padding: 0px;
    margin-bottom: 0px;
}

.to-ho-hotel-con-3 ul li {
    list-style-type: none;
    float: left;
}

.to-ho-hotel-con-3 ul li:last-child {
    float: right;
    padding-top: 10px;
}

.ho-hot-rat-star {
    padding-bottom: 0px !important;
}

.ho-hot-rat-star i {
    font-size: 14px !important;
    padding-left: 3px;
}

.ho-hot-rat-star-list i {
    color: #04abc0;
}

.hot-list-left-part-rat {
    width: 25px;
    height: 25px;
    background-color: #04abc0;
    /* bottom: 8px;
    */
    padding: 4px;
    border-radius: 2px;
    color: #fff;
    font-weight: 600;
    text-align: center;
    font-size: 12px;
    text-shadow: 0px -1px 0 rgba(0, 0, 0, 0.28);
    margin-right: 10px;
}

.ho-hot-pri {
    font-size: 38px;
    font-weight: 600;
    color: #f4364f;
    float: right;
}

.ho-hot-pri-dis {
    font-weight: 400;
    color: #888;
    float: right;
    padding: 0px 5px;
    display: none;
}

.hot-page2-hli-3 {
    width: 35px;
    height: 35px;
    position: absolute;
    background-color: rgba(255, 197, 37, 0.79);
    /* bottom: 8px;
    */
    padding: 4px;
    margin: 9px 5px;
    right: 5px;
    border-radius: 5px;
}

.to-ho-hotel-con-4 {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 14px;
}

.to-ho-hotel-con-4 a {
    float: left;
    width: 47%;
    text-align: center;
}

.to-ho-hotel-con-5 {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 12px;
}

.to-ho-hotel-con-5 ul {
    margin-bottom: 0px;
    padding: 0px;
}

.to-ho-hotel-con-5 ul li {
    float: left;
    list-style-type: none;
    display: inline-block;
    width: 25%;
}

.to-ho-hotel-con-5 ul li a {
    display: block;
    border: 1px solid #ececec;
    margin: 2px;
    padding: 0px 5px;
    font-size: 12px;
    color: #8a8a8a;
    text-align: center;
}

.to-ho-hotel-con-5 ul li a i {
    padding-right: 5px;
}

.hom-hot-book-btn {
    margin-right: 3%;
}

.hom-hot-view-btn {
    margin-left: 3%;
}

.hom-hot-av-tic {
    position: absolute;
    background-color: #8bc34a9e;
    bottom: 15px;
    padding: 3px 8px;
    margin: 0px 0px;
    right: 15px;
    font-weight: 500;
    color: #fff;
    border-radius: 25px;
    border: 1px solid #253747;
    font-family: 'Quicksand', sans-serif;
}

.hom-hot-av-tic-list {
    left: 10px;
    right: inherit;
    bottom: 10px;
    border-radius: 2px;
    background: rgba(139, 195, 74, 0.66);
    color: #fff;
    font-weight: 600;
    padding: 3px 8px;
    font-size: 13px;
}

/*-------------------------------------------------------*/
/* ROOMS: HOTEL BOOKING 
/*-------------------------------------------------------*/
.hotel-book-room {
    margin-bottom: 105px;
}

.carousel-inner1>.item>img,
.carousel-inner1>.item>a>img {
    width: 100%;
    margin: auto;
}

.carousel-indicators-1:hover {
    overflow-y: auto;
}

.tr-room-type ul {
    padding: 0px;
    margin-bottom: 0px;
}

.tr-room-type ul li {
    list-style-type: none;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #cecece;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.tr-room-type-list p b {
    color: #213d44;
}

.tr-room-type-list span b {
    color: #213d44;
}

.tr-room-type-list-1 {
    padding-left: 0px !important;
}

.tr-room-type-list-1 img {
    width: 100%;
}

.tr-room-type-list-2 h4 {
    margin-top: 0px;
    color: #213d44;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
}

.tr-room-type-list-2 span {
    display: block;
}

.tr-room-type-list-3 span {
    display: block;
    text-align: center;
    margin-bottom: 15px;
}

/*-------------------------------------------------------*/
/* 7.FREE CONSULTANT 
/*-------------------------------------------------------*/
.tour-consul {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat #00BCD4;
    background-size: cover;
    position: relative;
    overflow: hidden;
    background-attachment: fixed;
}

.tour-consul::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    bottom: 0px;
    background: rgba(19, 40, 58, 0.87);
}

.tour-consul h2 {
    color: #fff;
    text-transform: uppercase;
    font-size: 42px;
    margin-top: 25px;
    margin-bottom: 0px;
    /* border-top: 1px solid #1aa5d8;
    */
    /* border-bottom: 1px solid #1aa5d8;
    */
    /* padding-top: 20px;
    */
    /* padding-bottom: 20px;
    */
    /* margin-bottom: 25px;
    */
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.tour-consul p {
    color: #d4d8d8;
    font-size: 16px;
    font-weight: 500;
    margin-top: 24px;
    margin-bottom: 0px;
    border-top: 1px solid rgb(76, 84, 90);
    border-bottom: 1px solid rgb(76, 84, 90);
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.to-con-1 {
    background: #1aa5d8;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5px 8px;
}

.to-con-2 {
    color: #fff;
    font-size: 30px;
    margin-top: 5px;
    margin-bottom: 25px;
    /* text-transform: uppercase;
    */
    font-weight: 700;
    text-decoration: none;
    padding: 6px 15px;
    border-radius: 2px;
    display: inline-block;
    background: #1aa5d8;
    background: linear-gradient(to bottom, #2e9da2, #13b194);
    font-family: 'Quicksand', sans-serif;
}

.to-con-3 {
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    padding: 6px 15px;
    border-radius: 2px;
    display: inline-block;
    background: #1aa5d8;
    background: linear-gradient(to bottom, #1aa5d8, #1386b1);
    font-family: 'Quicksand', sans-serif;
}

.to-con-4 {
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    padding: 6px 15px;
    border-radius: 2px;
    display: inline-block;
    background: #1aa5d8;
    background: linear-gradient(to bottom, #1aa5d8, #1386b1);
    font-family: 'Quicksand', sans-serif;
}

.ho-vid img {
    width: 100%;
}

/*-------------------------------------------------------*/
/* 8.EVENTS 
/*-------------------------------------------------------*/
.events table {
    width: 100%;
}

.events table tr {
    line-height: 50px;
    border-bottom: 1px solid #e9e9e9;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.events table tr:hover {
    background: #e9f2f3;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.events table tr th {
    color: #4A505D;
    font-size: 18px;
    line-height: 40px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.events table tr td {
    color: #727070;
    font-size: 15px;
}

.events table tr td:first-child {
    text-align: center !important;
    padding-right: 5px;
}

.events table tr td a {
    text-decoration: none;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    font-weight: 700;
    text-decoration: none;
    color: #253d52;
    padding: 6px 15px;
    border-radius: 2px;
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
}

.events table tr td a:hover {
    background: #f4364f;
    background: linear-gradient(to bottom, #f4364f, #f4364f);
}

.events table tr th:first-child {
    text-align: center !important;
    padding-right: 5px;
}

.events-1 {
    font-family: 'Montserrat', sans-serif;
}

.events-1 h1 {
    margin-top: 0px;
}

.events img {
    width: 110px;
    padding: 10px 15px 10px 0px;
}

.events-title {
    background: none !important;
    /* font-family: 'Montserrat', sans-serif;
    */
    /* text-transform: uppercase;
    */
    font-size: 15px;
    margin-bottom: 30px;
    /* font-weight: 600;
    */
    /* color: #213d44;
    */
}

#myInput {
    background-image: url(/search.png);
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 100%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
    margin-bottom: 12px;
}

#myTable {
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #ddd;
    font-size: 18px;
}

#myTable th,
#myTable td {
    text-align: left;
    padding: 0px 12px;
}

#myTable tr {
    border-bottom: 1px solid #ddd;
}

#myTable tr.header,
#myTable tr:hover {
    background-color: #f1f1f1;
}

.events table tr:first-child:hover {
    background: none;
}

/*-------------------------------------------------------*/
/* 9.POPULAR TOUR PLACES 
/*-------------------------------------------------------*/
.place {
    margin-bottom: 30px;
}

.popu-places-home {
    position: relative;
    overflow: hidden;
}

.place img {
    width: 100%;
    border-radius: 5px;
    /* border: 3px solid #24272d;
    */
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.29);
}

.pla {
    background: #eff5fb;
    background-size: cover;
    /* margin-top: 35px;
    */
    position: relative;
    overflow: hidden;
    background-attachment: fixed;
}

.pla::before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0px;
    top: 0px;
    background: #f4364f;
    background: linear-gradient(to bottom, #f2f9ff, #e1e4e8);
}

.pla1 {
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.place h3 {
    color: #253d52;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 34px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}

.place h3 span {
    color: #253d52;
    display: block;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    text-transform: capitalize;
}

.place p {
    color: #253d52;
    padding: 10px 0px;
    margin-bottom: 10px;
    text-align: left;
    font-size: 14px;
}

.pla1 h1 {
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 36px;
    margin-top: 0px;
    padding-bottom: 40px;
    position: relative;
    /* padding-top: 15px;
    */
}

.pla1 h1 span {
    color: #00BCD4;
}

/*-------------------------------------------------------*/
/* 10.REQUEST A QUOTE 
/*-------------------------------------------------------*/
.form_1 ul {
    padding: 0px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
}

.form_1 ul li {
    list-style-type: none;
    width: 50%;
    float: left;
    padding: 0px 8px 16px 8px;
    box-sizing: border-box;
}

.form_1 ul li:last-child {
    padding-bottom: 0px;
}

.form_1 ul li input {
    border: 1px solid #bbbbbb;
    width: 100%;
    padding: 10px;
    border-radius: 3px;
}

.form_1 ul li input[placeholder] {
    color: #6b7a88;
}

.form_1 ul li select {
    color: #6b7a88;
}

.form_1 ul li textarea {
    border: 1px solid #bbbbbb;
    resize: none;
    border-radius: 3px;
    width: 100%;
}

.form_1 ul li select {
    border: 1px solid #bbbbbb;
    width: 100%;
    padding: 14px 10px;
    border-radius: 3px;
}

.form_book ul li:nth-child(13) {
    width: 100%;
}

.form_book ul li:nth-child(7) {
    width: 50% !important;
}

.form_1 ul li:nth-child(7) textarea {
    height: 120px;
    padding: 10px;
    background: #fff;
}

.form_1 ul li:nth-child(7) {
    width: 100%;
}

.form_1 ul li input[type="submit"] {
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 12px 15px;
    border-radius: 2px;
    display: inline-block;
    background: #1aa5d8;
    background: linear-gradient(to bottom, #1aa5d8, #1386b1);
    font-family: 'Quicksand', sans-serif;
    border: 0px;
    text-transform: uppercase;
}

.form {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat bottom center #fff;
    padding: 100px 0px 45px 0px;
    background-size: contain;
}

.form_booking {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat bottom center #f2f8f9;
    padding: 100px 0px 45px 0px;
    background-size: contain;
}

.form-spac {
    padding: 100px 0px;
}

.form_book_1 ul li:nth-child(11) {
    width: 100%;
}

/*-------------------------------------------------------*/
/* 10.REQUEST A QUOTE: FAMILY IMAGE 
/*-------------------------------------------------------*/
.family img {
    width: 95%;
    margin-bottom: -45px;
}

/*-------------------------------------------------------*/
/* 11.TIPS BEFORE TRAVEL 
/*-------------------------------------------------------*/
.tips {
    background: url(/tipsbg.jpg) no-repeat center center #f0f2f2;
    background-size: cover;
    position: relative;
    overflow: hidden;
    /* padding: 25px 0px 20px 0px;
    */
    /* margin-top: 40px;
    */
}

.home_tip {
    margin-top: 0px;
}

/*-------------------------------------------------------*/
/* 11.1 TIPS BEFORE TRAVEL 
/*-------------------------------------------------------*/
.tips h3 {
    background: #2ea5d3;
    display: inline-block;
    color: #ffffff;
    text-transform: uppercase;
    padding: 5px 8px 3px 8px;
    font-weight: 500;
    margin-top: 0px;
    font-size: 20px;
}

.tips_left {
    padding-left: 100px;
    padding-bottom: 25px;
}

.tips_left h5 {
    color: #0f0f0f;
    font-size: 18px;
}

.tips_left p {
    color: #1e2021;
    text-align: left;
}

.tips_left_1 {
    background: url(/tips1.png) no-repeat left top;
    margin-top: 25px;
}

.tips_left_2 {
    background: url(/tips2.png) no-repeat left top;
}

.tips_left_3 {
    background: url(/tips3.png) no-repeat left top;
    padding-bottom: 5px;
}

.tips p {
    line-height: 25px;
    text-align: left;
    margin-bottom: 12px;
}

/*-------------------------------------------------------*/
/* 11.2 CUSTOMER TESTIMONIALS 
/*-------------------------------------------------------*/
.testi {
    background: url(/testi_img.png) no-repeat left top;
    padding-left: 116px;
    margin-top: 20px;
    padding-bottom: 15px;
}

.testi p {
    color: #1e2021;
}

.testi address {
    color: #1e2021;
}

.testi a {
    background: #14A0D6;
    color: #000;
    /* font-weight: 600;
    */
    border-radius: 0px;
    padding: 5px 10px;
    text-decoration: none;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    border-radius: 50px;
}

.testi a:hover {
    background: #1aa5d8;
    color: #000;
}

.p-tesi {
    border: 1px #e0e0e0;
    border-style: dashed;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    padding: 20px 15px;
}

.p-tesi h4 {
    color: #253d52;
    margin-top: 0px;
}

.p-tesi address {
    margin-bottom: 0px;
    color: #253d52;
    /* font-style: italic;
    */
    font-weight: 600;
}

/*-------------------------------------------------------*/
/* 11.3 ARRANGEMENTS & HELPS 
/*-------------------------------------------------------*/
.arrange {
    padding-top: 12px;
}

.arrange ul {
    padding: 0px;
    margin-bottom: 0px;
}

.arrange ul li {
    width: 48%;
    /* float: left;
    */
    list-style-type: none;
    padding: 5px;
    /* box-sizing: content-box;
    */
    display: inline-block;
}

.arrange ul li a img {
    width: 100%;
}

/*-------------------------------------------------------*/
/* 12.FOOTER 1 
/*-------------------------------------------------------*/
.footer {
    background: #000000;
    position: relative;
    overflow: hidden;
    padding-bottom: 85px;
}

.footer h5 {
    color: #acadaf;
    text-transform: uppercase;
    padding-bottom: 15px;
}

.foot-social ul {
    padding: 0px;
}

.foot-social ul li {
    list-style-type: none;
    float: left;
    padding-right: 10px;
}

.foot-social ul li svg {
    font-size: 16px;
    width: 20px;
    height: 20px;
    border: 1px solid #2d2d2d;
    border-radius: 50px;
    padding: 7px;
    text-align: center;
    /* background: #1aa5d8;
    */
    color: #696969;
}

footer ul li a {
    color: #6e7173;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.foot-social ul li a:hover {
    padding-left: 0px;
}

.foot-logo img {
    padding-bottom: 20px;
}

.foot-spec span {
    color: #acadaf;
    text-transform: uppercase;
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.foot-sec2 p {
    color: #616161;
}

.two-columns {
    padding: 0px;
}

.two-columns li {
    width: 50%;
    float: left;
    list-style-type: none;
}

.two-columns::after {
    content: "";
    display: table;
    clear: both;
}

.two-columns li a {
    color: #616161;
}

/*-------------------------------------------------------*/
/* 13.5 SOCIAL MEDIA 
/*-------------------------------------------------------*/
.footer_social ul {
    padding: 0px;
}

.footer_social ul li {
    display: inline-block;
}

.footer_social ul li a {
    color: #fff;
    text-decoration: none;
}

.footer_social ul li a .fa-facebook {
    background: #3b5998;
    padding: 10px 15px;
}

.footer_social ul li a .fa-google-plus {
    background: #f4364f;
    padding: 10px 10px;
}

.footer_social ul li a .fa-twitter {
    background: #55acee;
    padding: 10px 12px;
}

.footer_social ul li a .fa-linkedin {
    background: #0077b5;
    padding: 11px 12px;
}

.footer_social iframe {
    width: 100%;
    display: block;
    pointer-events: none;
    position: relative;
    /* IE needs a position other than static */
}

.footer_social iframe.clicked {
    pointer-events: auto;
}

/*-------------------------------------------------------*/
/* 13.2 SUBSCRIBE 
/*-------------------------------------------------------*/
.footer_sub input {
    width: 100%;
    border: 0px;
    padding: 7px 5px;
    margin-bottom: 12px;
    border-radius: 1px;
}

.footer_sub input[type="submit"] {
    background: #FF5722;
    color: #000;
    font-weight: 600;
    border-radius: 0px;
    padding: 5px 10px;
    text-decoration: none;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    border-radius: 1px;
    width: 50%;
    float: right;
    margin-bottom: 45px;
}

/*-------------------------------------------------------*/
/* 13.2 FAQ 
/*-------------------------------------------------------*/
.footer_faq ul {
    padding: 0px;
}

.footer_faq ul li {
    list-style-type: none;
}

.footer_faq ul li a {
    display: block;
    color: #fff;
    text-decoration: none;
    line-height: 30px;
    text-transform: uppercase;
}

.footer_faq p {
    color: #fff;
}

.mob_logo {
    display: none;
}

/*-------------------------------------------------------*/
/* 12.1 OFFERS & DISCOUNTS 
/*-------------------------------------------------------*/
.disco {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center;
    background-size: cover;
    padding: 20px 15px 22px 15px;
    text-align: center;
    /* margin-bottom: 30px;
    */
}

.disco1 {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center;
    background-size: cover;
}

.disco h3 {
    color: #FFEB3B;
    font-size: 38px;
    margin-top: 0px;
    font-weight: 700;
}

.disco h3 span {
    color: #fff;
}

.disco h4 {
    color: #fff;
}

.disco p {
    color: #fff;
    margin-top: 0px;
}

.disco a {
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 6px 15px;
    border-radius: 2px;
    display: inline-block;
    font-family: 'Quicksand', sans-serif;
    /* padding: 6px;
    */
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    font-weight: 700;
    background: #f4364f;
    background: linear-gradient(to bottom, #f4364f, #dc2039);
}

.disco a:hover {
    background: #f4364f;
    color: #fff;
}

/*-------------------------------------------------------*/
/* 12.2 MOST POPULAR VACATIONS 
/*-------------------------------------------------------*/
.footer1 {
    background: #000000;
    padding: 100px 0px 50px 0px;
    border-top: 1px solid;
}

.footer_places h4 {
    color: #acadaf;
    margin-top: 0px;
    padding-bottom: 15px;
    text-transform: uppercase;
}

.footer_places ul {
    padding: 0px;
}

.footer_places ul li {
    display: inline-block;
    /* border-right: 1px solid #9d9d9d;
    */
    /* padding: 0px 15px;
    */
    padding-right: 3px;
    line-height: 35px;
}

.footer_places ul li a {
    color: #989898;
    text-decoration: none;
    /* border-right: 1px solid #9d9d9d;
    */
    background: #2d2d2d;
    padding: 5px;
}

.footer_places ul li a:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    background: #126164;
}

/*-------------------------------------------------------*/
/* 14.COPY RIGHTS 
/*-------------------------------------------------------*/
.copy {
    background: #fff;
}

.copy p {
    text-align: center;
    margin-bottom: 0px;
    padding: 5px;
    font-size: 13px;
    color: #000000;
}

.open>.dropdown-menu {
    -webkit-transition: background-color 0.3s ease 0s, border 0s ease 0s, color 0.3s ease 0s, opacity 0.3s ease-in-out 0s;
    -moz-transition: background-color 0.3s ease 0s, border 0s ease 0s, color 0.3s ease 0s, opacity 0.3s ease-in-out 0s;
    -o-transition: background-color 0.3s ease 0s, border 0s ease 0s, color 0.3s ease 0s, opacity 0.3s ease-in-out 0s;
    transition: background-color 0.3s ease 0s, border 0s ease 0s, color 0.3s ease 0s, opacity 0.3s ease-in-out 0s;
}

/*-------------------------------------------------------*/
/* 15.INNER PAGE 
/*-------------------------------------------------------*/
/*-------------------------------------------------------*/
/* 15.1 COMMON BANNER 
/*-------------------------------------------------------*/
.inner_banner {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center;
    background-size: cover;
    padding: 250px 0px 150px 0px;
    position: relative;
}

.inner_banner::before {
    content: '';
    position: absolute;
    background: linear-gradient(to top, rgb(29, 36, 42) 15%, rgba(0, 0, 0, 0) 100%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.inner_banner_1 {
    background: url(http://thuthuatphanmem.vn/uploads/2018/05/23/wallpaper-4k-hinh-nen-4k-khe-nui-da-dep_100009128.jpg) no-repeat center center;
    background-size: cover;
}

.inner_banner_2 {
    background: url(http://thuthuatphanmem.vn/uploads/2018/05/23/wallpaper-4k-hinh-nen-4k-khung-canh-thien-nhien-mua-thu-dep_100009440.jpeg) no-repeat center center;
    background-size: cover;
}

.inner_banner_3 {
    background: url(http://thuthuatphanmem.vn/uploads/2018/05/23/wallpaper-4k-hinh-nen-4k-khung-canh-thien-nhien-ven-bien-dep_100009580.jpg) no-repeat center center;
    background-size: cover;
}

.inner_banner_4 {
    background: url(http://thuthuatphanmem.vn/uploads/2018/05/23/wallpaper-4k-hinh-nen-4k-ky-quan-thien-nhien-dep_100009861.jpg) no-repeat center center;
    background-size: cover;
}

.inner_banner_5 {
    background: url(http://thuthuatphanmem.vn/uploads/2018/05/23/wallpaper-4k-hinh-nen-4k-lieu-ru-ven-ho-dep_100010079.jpg) no-repeat center center;
    background-size: cover;
}

.inner_banner div {
    position: relative;
}

.inner_banner h2 {
    color: #f4364f;
    font-size: 32px;
    display: inline-block;
    font-weight: 600;
}

.inner_banner h2 span {
    color: #FFF;
    font-family: 'Quicksand', sans-serif;
    font-size: 32px;
    font-weight: 600;
}

.inner_banner p {
    color: #d6d6d6;
    font-size: 20px;
    font-weight: 100;
}

.inner_banner ul {
    margin-top: 25px;
    float: right;
    margin-bottom: 0px;
}

.inner_banner ul li {
    float: left;
    display: inline-block;
    color: #fff;
    padding: 0px 5px;
}

.inner_banner a {
    color: #fff;
    font-size: 15px;
}

.inner_banner a i {
    color: #fff;
}

.bread-acti {
    color: #d6d6d6 !important;
}

.bg-none {
    background: none;
}

/*-------------------------------------------------------*/
/* TOUR PLACES 
/*-------------------------------------------------------*/
.p2_2 {
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
    width: 100%;
}

.p2_1 img {
    width: 100%;
}

.p2 h3 {
    color: #253d52;
    margin-top: 0px;
}

.p2 h3 span {
    display: block;
}

.p2 p {
    text-align: left;
    color: #868686;
    line-height: 25px;
    font-size: 16px;
    margin-bottom: 15px;
}

.p2 .fa-star {
    color: #FF9800;
    font-size: 16px;
}

.p2 .fa-star-half-o {
    color: #9E9E9E;
    font-size: 16px;
}

/*-------------------------------------------------------*/
/* TICKETS 
/*-------------------------------------------------------*/
.ticket ul {
    padding: 0px;
    margin-bottom: 5px;
}

.ticket ul li {
    display: inline-block;
    background: #efefef;
    padding: 2px 7px;
    font-size: 14px;
    font-weight: 600;
    color: #253d52;
    margin-right: 1px;
}

.ticket ul li:nth-child(1) {
    background: #a2dd2f;
    color: #253d52;
}

/*-------------------------------------------------------*/
/* FEATURES 
/*-------------------------------------------------------*/
.featur {
    position: relative;
    overflow: hidden;
    padding-bottom: 10px;
}

.featur h4 {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center left;
    margin-top: 25px;
    padding-left: 32px;
    margin-top: 15px;
    color: #253d52;
    background-size: contain;
}

.featur ul {
    padding: 0px;
    margin-bottom: 0px;
}

.featur ul li {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center left;
    display: inline-block;
    padding: 3px;
    color: #253d52;
    padding-left: 24px;
    font-size: 14px;
    font-weight: 600;
}

/*-------------------------------------------------------*/
/* BOOKING 
/*-------------------------------------------------------*/
.p2_book ul {
    padding: 0px;
}

.p2_book ul li {
    display: inline-block;
}

.p2_book ul li a {
    display: table;
}

.p2_book ul li a:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    background: #1695c4;
    color: #fff;
    /* text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.35);
    */
}

.p2_book ul li:nth-child(2) a {
    background: #c08e21;
    background: linear-gradient(to bottom, #d2a032, #b18013);
}

.p2_book ul li:nth-child(2) a:hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    color: #fff;
    background: #7eb73c;
    background: linear-gradient(to bottom, #8BC34A, #70ab2c);
}

.banner_book {
    background: #253d52;
    z-index: 9999;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.37);
}

.banner_book_1 ul {
    padding: 0px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
}

.banner_book_1 ul li {
    display: inline;
    line-height: 55px;
    font-size: 18px;
    border-right: 1px solid #314c64;
    color: #fff;
    float: left;
    width: 24%;
    text-align: center;
}

.banner_book_1 ul li:last-child {
    border-right: 0px solid rgba(3, 3, 3, 0.12);
    padding-left: 0px;
}

.banner_book_1 ul li a {
    background: #1aa5d8;
    background: #f4364f;
    background: linear-gradient(to bottom, #f4364f, #dc2039);
    display: block;
    text-align: center;
    color: #fff;
    /* text-transform: uppercase;
    */
    font-size: 22px;
    font-weight: 600;
    text-decoration: none;
    line-height: inherit;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.banner_book_1 ul li a:hover {
    text-decoration: none;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    background: #dc2039;
}

/*-------------------------------------------------------*/
/* PAGE: BLOG POSTS 
/*-------------------------------------------------------*/
.posts {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 50px;
}

.posts2 img {
    margin-top: 25px;
}

.posts img {
    width: 100%;
}

.posts h3 {
    color: #212627;
    margin-top: 0px;
    padding-bottom: 5px;
    text-transform: capitalize;
}

.posts h5 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
}

.posts p {
    text-align: left;
    color: #868686;
    line-height: 25px;
    margin-bottom: 25px;
    font-size: 13px;
}

.post_author {
    font-family: 'Quicksand', sans-serif;
    font-weight: 700;
}

.post_date {
    color: #000000;
    padding-left: 8px;
    font-weight: 500;
    font-size: 13px;
}

.post_city {
    color: #000000;
    padding-left: 8px;
    font-weight: 500;
    font-size: 13px;
}

/*-------------------------------------------------------*/
/* PAGE: TOUR DETAIL 
/*-------------------------------------------------------*/
.tour_font {
    margin-bottom: 50px;
}

.tour_font p {
    text-align: left;
    color: #868686;
    font-size: 15px;
    line-height: 25px;
}

.tour_head h2 {
    color: #213d44;
    /* text-transform: uppercase;
    */
    font-weight: 600;
    margin-top: 0px;
    font-size: 36px;
}

.tour_star i {
    font-size: 18px;
    color: #FF9800;
}

.tour_rat {
    background: #FF5722;
    color: #fff;
    font-size: 16px;
    padding: 0px 5px;
    border-radius: 2px;
    margin-left: 8px;
}

.tour_head1 h3 {
    background: url(/umbrella.png) no-repeat left center;
    padding-left: 35px;
    color: #213d44;
    margin-bottom: 20px;
    margin-top: 50px;
    background-size: 16px;
    /* text-transform: uppercase;
    */
    font-weight: 600;
    font-size: 22px;
}

.tour_head1 p {
    color: #7f7f7f;
    font-weight: 500;
}

.tour_head1 table {
    width: 100%;
}

.tour_head1 table tr {
    border-bottom: 1px solid #e5e5e5;
}

.tour_head1 table tr th {
    color: #2e2e2e;
    /* line-height: 40px;
    */
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 10px;
}

.tour_head1 table tr td {
    line-height: 40px;
    color: #7f7f7f;
    font-weight: 500;
}

.days h4 {
    margin: 25px 0px 15px 0px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    color: #213d44;
}

.days h4 span {
    color: #929596;
}

.l-info-pack-days ul {
    padding: 0px;
    padding-top: 5px;
}

.l-info-pack-days ul li {
    position: relative;
    overflow: hidden;
    padding-left: 70px;
}

.l-info-pack-days ul li svg {
    width: 15px;
    height: 15px;
    background: #dc2039;
    border-radius: 50%;
    text-align: center;
    padding: 9px 10px;
    margin-left: -68px;
    position: absolute;
    z-index: 99;
    color: #fff;
    font-size: 18px;
}

.l-info-pack-days ul li h4 {
    margin-top: 7px;
}

.l-info-pack-days ul li p {
    padding-top: 0px;
    font-size: 13px;
}

.l-info-pack-plac::after {
    content: '';
    background: #cecece;
    position: absolute;
    width: 1px;
    top: 0;
    bottom: 0;
    height: 100%;
    left: 19px;
}

.tour_right h3 {
    background: #253d52;
    padding-left: 40px;
    color: #ffffff;
    font-size: 14px;
    background-size: 22px;
    /* margin: 24px 0px;
    */
    margin-top: 0px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 15px;
    margin-bottom: 0px;
}

.tour_incl ul li {
    list-style-type: none;
    background: url(/tick.png) no-repeat left center;
    padding-left: 30px;
    /* line-height: 34px;
    */
    /* font-size: 16px;
    */
    padding-bottom: 8px;
}

.tour_right ul {
    padding: 15px;
    margin-bottom: 0px;
}

.tour_offer {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center;
    padding: 20px;
    background-size: cover;
    margin-bottom: 30px;
}

.tour_offer p {
    text-align: center;
    color: #1aa5d8;
}

.tour_offer h4 {
    color: #fff;
    font-size: 50px;
    text-align: center;
}

.tour_offer h4 span {
    color: #F12860;
    font-size: 25px;
}

.tour_offer ul {
    list-style-type: none;
    margin-bottom: 0px;
}

.tour_offer ul li {
    display: inline-block;
    width: 49%;
}

.tour_offer a {
    background: #1aa5d8;
    display: block;
    color: #000;
    text-align: center;
    padding: 5px;
    font-weight: 600;
    border-radius: 45px;
    font-size: 15px;
}

.band1 {
    width: 50px;
    height: auto;
    position: absolute;
    z-index: 99;
    /* left: 13px;
    */
    top: -4px;
    right: 61px;
}

.tour-ri-com {
    position: relative;
    overflow: hidden;
    /* background: #f3f3f3;
    */
    border: 1px solid #e2e2e2;
    /* padding: 15px;
    */
    margin-bottom: 30px;
}

.hotel-com-color p {
    color: #868686;
}

.hotel-gal-arr {
    margin-top: 175%;
    position: absolute;
    background: rgb(0, 193, 255);
    width: 32px;
    height: 32px;
    padding: 4px;
    text-align: center;
    border-radius: 2px;
    left: 24px;
    font-size: 24px;
}

.hotel-gal-arr1 {
    right: 10px;
    position: relative;
}

/*-------------------------------------------------------*/
/* PAGE: DETAIL PAGE - SOCIAL MEDIA 
/*-------------------------------------------------------*/
.tour_social ul {
    float: left;
    padding: 15px 15px 10px 15px;
}

.tour_social ul li {
    margin-bottom: 5px;
}

.tour_social ul li:nth-child(2) {
    padding-right: 0px;
}

.tour_social ul li a svg {
    width: 15px;
    height: 15px;
    color: #fff;
    padding: 8px;
    text-align: center;
    border-radius: 50%;
}

.tour_social ul li:nth-child(3) {
    padding-right: 7px;
}

.tour_social ul li:nth-child(4) {
    padding-right: 7px;
}

/*-------------------------------------------------------*/
/* PAGE: DETAIL PAGE - PACKAGE HELP 
/*-------------------------------------------------------*/
.tour_help_1 {
    padding: 15px;
    text-align: center;
}

.tour_help_1 h4 {
    margin-bottom: 0px;
    font-size: 24px;
}

.tour_help_1_call {
    color: #f4364f;
    margin-top: 0px;
}

/*-------------------------------------------------------*/
/* RELATED PACKAGES 
/*-------------------------------------------------------*/
.tour_rela_1 {
    position: relative;
    overflow: hidden;
    margin-top: 0px;
    padding: 15px;
}

.tour_rela_1 img {
    width: 100%;
}

.tour_rela_1 h4 {
    margin-top: 15px;
    font-size: 15px;
    font-weight: 600;
}

.tour_rela_1 p {
    color: #868686;
}

.tour_booking {
    position: relative;
    overflow: hidden;
}

.book_poly h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 28px;
    color: #253d52;
    margin-top: 0px;
    padding-bottom: 12px;
    font-weight: 600;
}

.book_poly ul li {
    line-height: 35px;
    color: #464646;
    font-size: 16px;
}

.book_poly p {
    color: #464646;
}

/*-------------------------------------------------------*/
/* PAGE: ABOUT 
/*-------------------------------------------------------*/
.tourb2-ab-p-2 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.tourb2-ab-p1 {
    margin-top: 60px;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.tourb2-ab-p1-left h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    color: #253d52;
}

.tourb2-ab-p1-left span {
    display: inline-block;
    padding: 20px 0px;
    font-size: 20px;
    font-style: italic;
    font-weight: 200;
    color: #a2a2a2;
}

.tourb2-ab-p1-left p {
    line-height: 24px;
}

.tourb2-ab-p1-left a {
    color: #000000;
    background: #1693c1;
    padding: 10px 14px;
    display: inline-block;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 2px;
    margin-top: 12px;
}

.tourb2-ab-p1-right img {
    width: 100%;
}

.tourb2-ab-p-3 {
    background: #253d52;
    padding: 50px 0px 20px 0px;
}

.tourb2-ab-p3-com {
    /* background: #fff;
    */
    padding: 15px 15px 10px 15px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 2px;
}

.tourb2-ab-p3-com span {
    font-size: 42px;
    font-weight: 600;
    float: left;
    padding-right: 15px;
    color: #ffc210;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.43);
}

.tourb2-ab-p3-com h4 {
    font-size: 24px;
    font-weight: 600;
    padding-top: 2px;
    text-transform: uppercase;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.43);
    color: #fff;
}

.tourb2-ab-p3-com p {
    display: block;
    /* position: absolute;
    */
    float: left;
    width: 100%;
}

.tourb2-ab-p3-1 p {
    font-size: 18px;
    font-weight: 300;
    color: #b7b7b7;
}

/*--================================================--*/
.tourb2-ab-p-4 {
    background: #ffffff;
    padding: 100px 0px 70px 0px;
}

.tourb2-ab-p4-com {
    background: #fff;
    padding: 25px 25px 16px 25px;
    position: relative;
    overflow: hidden;
    border: 1px #e0e0e0;
    border-style: dashed;
    margin-bottom: 30px;
}

.tourb2-ab-p4-com i {
    font-size: 42px;
    float: left;
    padding-right: 25px;
    color: #00bcd4;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.43);
}

.tourb2-ab-p4-com h4 {
    padding-bottom: 15px;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #253d52;
}

.tourb2-ab-p4-com h4 span {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    color: #00bcd4;
}

.tourb2-ab-p4-com p {
    display: block;
    /* position: absolute;
    */
    float: left;
    width: 100%;
}

.tourb2-ab-p4-text {
    position: relative;
    overflow: hidden;
}

.about h1 {
    padding-bottom: 15px;
}

.about p {
    text-align: left;
    margin-bottom: 15px;
}

.about_ser {
    position: relative;
    overflow: hidden;
}

.about_ser ul {
    padding: 0px;
}

.about_ser ul li {
    display: inline-block;
    text-align: center;
    border: 1px solid #ffffff;
    font-size: 18px;
    padding: 20px;
    color: #ffffff;
    box-sizing: border-box;
    background: #55c0ea;
    margin-bottom: 4px;
}

.about_ser ul li i {
    font-size: 34px;
    color: #f1f1f1;
    text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.08);
}

.about_ser ul li:hover {
    background: #3a3e44;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    color: #b5b5b5;
}

/*-------------------------------------------------------*/
/* PAGE: TESTIMONIALS 
/*-------------------------------------------------------*/
.p_testi h4 {
    font-weight: 600;
    color: #3a3a3a;
}

.p_testi p {
    color: #848484;
}

.p_title h2 {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat left center;
    padding-left: 50px;
    color: #212627;
    margin-bottom: 20px;
    margin-top: 30px;
    background-size: 25px;
}

.p_title h2 span {
    color: #2ea5d3;
}

.p_testi address {
    color: #a2a2a2;
    margin-bottom: 0px;
}

.p_testimonial {
    position: relative;
    overflow: hidden;
}

.p_testimonial p {
    text-align: left;
    margin-bottom: 10px;
    line-height: 24px;
    color: #888;
    padding-top: 10px;
}

.p_testimonial h1 {
    padding-bottom: 5px;
}

/*-------------------------------------------------------*/
/* PAGE: TIPS BEFORE TRAVEL 
/*-------------------------------------------------------*/
.tips_travel h3 {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat left center;
    padding-left: 50px;
    color: #212627;
    margin-bottom: 20px;
    margin-top: 30px;
    background-size: 25px;
}

.tips_travel p {
    text-align: left;
    margin-left: 50px;
    margin-bottom: 0px;
}

.tips_travel_1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-top: 50px;
}

.tips_travel_1 ul {
    padding: 0px;
}

.tips_travel_1 ul li {
    float: left;
    list-style-type: none;
}

.tips_travel_2 {
    text-align: center;
    background: #ffffff;
    border: 1px #e0e0e0;
    border-style: dashed;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    padding: 25px 15px 15px 15px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.tips_travel_2:hover {
    box-shadow: 0px 15px 32px -5px rgba(150, 150, 150, 0.4);
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.tips_travel_2:hover i {
    color: #ffffff;
    background: #253d52;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    border: 0px;
}

.tips_travel_2 i {
    width: 65px;
    height: 65px;
    background: #ffc210;
    border-radius: 50px;
    color: #253d52;
    /* box-shadow: 0px 1px 5px rgba(150, 150, 150, 0.4);
    */
    padding: 21px 18px;
    font-size: 24px;
    text-align: center;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    /* background: #ffffff;
    */
    border: 1px #e0e0e0;
    border-style: dashed;
    text-shadow: 0px 1px #fff, 0px -1px #262F33;
}

.tips_travel_2 h4 {
    color: #253d52;
    margin-top: 25px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

.tips_travel_2 p {
    font-size: 15px;
    font-weight: 400;
}

/*-------------------------------------------------------*/
/* PAGE: TOUR PACKAGE 
/*-------------------------------------------------------*/
.pack_title {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center;
    padding: 20px;
    background-size: cover;
    text-align: center;
}

.pack_title h3 {
    color: #fff;
    font-size: 24px;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.pack_title h2 {
    color: #1aa5d8;
    font-weight: 600;
    font-size: 50px;
    text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.8);
}

.pack_title h2 span {
    color: #fff;
    font-size: 29px;
}

/*-------------------------------------------------------*/
/* PAGE: DISCOUNT PRICE TABLE 
/*-------------------------------------------------------*/
.pack_price {
    background: rgba(237, 237, 237, 0.32);
    margin-bottom: 50px;
    box-shadow: 0px 13px 13px -12px rgba(0, 0, 0, 0.4);
    transition: all 0.5s ease;
}

.pack_price:hover .pack_price ul li a {
    background: #FFD600;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    color: #000;
}

.pack_price ul {
    padding: 0px;
}

.pack_price ul li {
    text-align: center;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 42px;
    color: #000;
    letter-spacing: 0.3px;
    border-bottom: 1px solid #eceaea;
}

.pack_price ul li a {
    display: block;
    padding: 6px;
    font-weight: 700;
    background: #00bcd4;
    color: #000;
    font-size: 20px;
    text-transform: uppercase;
}

.pack_price ul li a:hover {
    background: #FFD600;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    color: #000;
}

.pack_price ul li:last-child {
    border-bottom: 0px;
}

.disc-bot {
    padding-bottom: 35px;
}

/*-------------------------------------------------------*/
/* PAGE: SITE MAP 
/*-------------------------------------------------------*/
.site_map {
    padding: 0px 15px;
}

.site_map ul {
    padding: 0px;
}

.site_map ul li {
    list-style-type: circle;
}

.site_map ul li a {
    font-weight: 600;
    color: #253d52;
    line-height: 32px;
    font-size: 15px;
}

.site_map ul li ul {
    padding-left: 50px;
}

.nf {
    text-align: center;
    font-size: 34px;
    color: #E06125;
}

.site-map-2 h1 {
    padding-bottom: 30px;
}

.nf1 {
    text-align: center;
    font-size: 120px;
    color: #131313;
    text-shadow: 0px 3px 5px rgba(22, 111, 182, 0.74);
}

.links {
    margin: 0 auto;
    display: table;
    text-align: center;
}

.links h4 {
    padding-bottom: 15px;
}

.links ul {
    padding: 0px;
}

.links ul li {
    display: inline-block;
    margin-top: 10px;
}

.links ul li a {
    text-decoration: none;
    /* background: #4d90fe;
    */
    border: 1px solid #131313;
    padding: 5px;
    color: #131313;
    border-radius: 4px;
    font-weight: 500;
}

.rnslider .button {
    position: absolute;
}

/*-------------------------------------------------------*/
/* PAGE: CONTACT US 
/*-------------------------------------------------------*/
.footer_social_1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 25px;
}

.footer_social_1 ul {
    display: table;
    margin: 0 auto;
}

.footer_social_1 ul li {
    margin: 0px 3px;
}

.footer_social_1 ul li a i {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.contact-map {
    margin-bottom: -10px;
}

.contact-map iframe {
    width: 100%;
    height: 320px;
}

.map-container iframe {
    width: 100%;
    display: block;
    pointer-events: none;
    position: relative;
    margin-top: 1px;
    border: 0px;
}

.map-container iframe.clicked {
    pointer-events: auto;
}

.succ_mess {
    background: #58b533;
    color: #fff;
    display: none;
    text-align: center;
    padding: 5px;
    margin-bottom: 20px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.succ_mess_qe {
    background: #58b533;
    color: #fff;
    display: none;
    text-align: center;
    padding: 5px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.form_1 h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 28px;
    color: #1B3033;
    padding: 0px 8px 10px 8px;
    margin-top: 0px;
}

.post-grid {
    margin-bottom: 45px;
    margin-top: 25px;
}

.n-td {
    color: red;
    text-decoration: line-through;
}

.n-td-1 {
    color: #bebebe
}

/*-------------------------------------------------*/
/* PRE LOADING 
/*-------------------------------------------------*/
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    /* change if the mask should have another color then white */
    z-index: 99999;
    /* makes sure it stays on top */
}

#status {
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    /* centers the loading animation horizontally one the screen */
    top: 50%;
    /* centers the loading animation vertically one the screen */
    background-image: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg);
    /* path to your loading animation */
    background-repeat: no-repeat;
    background-position: center;
    margin: -100px 0 0 -100px;
    /* is width and height divided by two */
}

/*-------------------------------------------------*/
/* REVIEWS 
/*-------------------------------------------------*/
.dir-rat {
    position: relative;
    overflow: hidden;
    border: 1px solid #dedede;
    padding: 25px 25px 0px 25px;
    background: #ffffff;
    margin-top: 50px;
}

fieldset,
label {
    margin: 0;
    padding: 0;
}

.dir-rat h3 {
    font-size: 18px;
}

.dir-rat-inn {
    position: relative;
    overflow: hidden;
}

.dir-rat h3 {
    font-size: 18px;
}

.dir-rat-form {
    position: relative;
    overflow: hidden;
    margin-bottom: 35px;
}

.dir-rat-form label {
    margin-bottom: 10px;
    margin-top: 5px;
    color: #213d44;
}

.dir-rat-form input,
textarea {
    width: 100%;
    border: 1px solid #dedede;
    border-radius: 3px;
    resize: none;
    padding: 18px;
    box-shadow: none;
}

.dir-rat-form textarea {
    height: 120px;
}

.dir-rat-form input[type="submit"] {
    border: 0px;
    padding: 7px;
    width: 250px;
}

/****** Style Star Rating Widget *****/
.rating {
    border: none;
    float: left;
    height: 48px;
    overflow: hidden;
}

.rating>input {
    display: none;
}

.rating>label:before {
    margin: 5px;
    font-size: 22px;
    font-family: FontAwesome;
    display: inline-block;
    content: "\f005";
    border: 0px !important;
}

.rating>label:after {
    background: none !important;
    border: 0px !important;
}

.rating>.half:before {
    content: "\f089";
    position: absolute;
    border: 0px;
}

.rating>label {
    color: #ddd;
    float: right;
}

.half {
    display: none !important;
}

.dir-rat-review {
    border: 1px solid #dedede;
    padding: 25px;
    background: #ffffff;
    margin-bottom: 30px;
}

.dir-rat-left {
    text-align: center;
}

.dir-rat-left img {
    border-radius: 50px;
    margin-bottom: 20px;
}

.dir-rat-left p {
    font-size: 16px;
    margin-bottom: 30px;
    font-weight: 600;
    color: #213d44;
}

.dir-rat-left p span {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #888;
}

.dir-rat-right p {
    margin-bottom: 0px;
    padding-bottom: 20px;
}

.dir-rat-right ul {
    padding: 0px;
}

.dir-rat-right ul li {
    float: left;
    list-style-type: none;
    display: inline-block;
    padding-right: 15px;
}

.dir-rat-right ul li a {
    color: #4d90fe;
    font-size: 14px;
}

.dir-rat-star {
    padding-bottom: 20px;
}

.dir-rat-star i {
    font-size: 16px;
    color: #FF9800;
    margin-right: 2px;
}

.dir-rat-right ul li span {
    padding-right: 7px;
    color: #888;
    font-size: 12px;
}

.dir-rat-title h3 {
    margin-top: 0px;
    color: #213d44;
    background-size: 25px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
}

/*-------------------------------------------------*/
/* TOP TRENDINGS 
/*-------------------------------------------------*/
.hot-page2-hom-pre {
    position: relative;
    overflow: hidden;
    background: #ffffff;
    padding: 15px;
    border: 1px solid #e7e7e7;
    margin-bottom: 30px;
}

.hot-page2-hom-pre ul {
    padding: 0px;
}

.hot-page2-hom-pre ul li {
    list-style-type: none;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    overflow: hidden;
    padding: 15px 0px;
}

.hot-page2-hom-pre ul li:last-child {
    border-bottom: 0px;
    padding-bottom: 0px;
}

.hot-page2-hom-pre div {
    float: left;
}

.hot-page2-hom-pre-1 {
    width: 10%;
}

.hot-page2-hom-pre-1 img {
    width: 30px;
    height: 30px;
    border-radius: 2px;
}

.hot-page2-hom-pre-2 {
    width: 80%;
    padding: 0px 15px;
}

.hot-page2-hom-pre-2 h5 {
    margin-top: 0px;
    margin-bottom: 2px;
    color: #343c42;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.hot-page2-hom-pre-2 span {
    font-size: 13px;
    color: #999;
}

.hot-page2-hom-pre-3 {
    width: 10%;
}

.hot-page2-hom-pre-3 span {
    /* width: 24px;
    */
    /* height: 24px;
    */
    position: absolute;
    /* background-color: #d6d6d6;
    */
    border: 1px solid #8b9ba9;
    /* bottom: 8px;
    */
    padding: 0px 4px;
    /* margin: 10px 5px;
    */
    /* right: 5px;
    */
    border-radius: 2px;
    font-size: 12px;
    z-index: 9;
    line-height: 20px;
    color: #253d52;
    font-weight: 600;
    text-align: center;
    text-shadow: 0px -1px 0 rgba(0, 0, 0, 0.28);
    right: 0px;
}

.hot-page2-hom-pre-3 svg {
    width: 20px;
    height: 20px;
    float: right;
    /* padding-top: 12px;
    */
    border: 1px solid #a9a9a9;
    padding: 4px;
    font-size: 12px;
    border-radius: 50%;
    color: #a9a9a9;
    margin-top: 10px;
}

.hot-page2-hom-pre-tit h3 {
    color: #343c42;
    font-size: 28px;
    /* font-family: 'Montserrat', sans-serif;
    */
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.hot-page2-hom-pre-tit h3 span {
    color: #6f6e6b;
    font-size: 28px;
}

.hot-page2-hom-pre ul li:hover .hot-page2-hom-pre-2 h5 {
    padding-left: 15px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.hot-page2-hom-pre-head {
    background: #253d52;
    color: #fff;
    padding: 10px 0px;
    text-align: center;
}

.hot-page2-hom-pre-head h4 {
    color: #ffffff;
}

.hot-page2-hom-pre-head h4 span {
    color: #9dacb1;
    font-weight: 600;
    font-size: 18px;
}

.hot-page2-hom-pre-head:after,
.hot-page2-hom-pre-head:before {
    top: 59px;
    left: 48%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.hot-page2-hom-pre-head:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #253d52;
    border-width: 9px;
    margin-left: -9px;
    z-index: 9;
}

/*-------------------------------------------------*/
/* INNER PAGE LISTING 
/*-------------------------------------------------*/
.hot-page2-il-top-fix {
    opacity: 1 !important;
    display: block;
}

.hot-page2-alp {
    background: #ffffff;
    background-attachment: fixed;
    background-size: cover;
}

.all-hot-bg {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center top #e6e6e6;
    background-attachment: fixed;
}

.hot-page2-alp::before {
    content: '';
    position: fixed;
    /* background: linear-gradient(to top, rgb(29, 36, 42) 15%, rgba(0, 0, 0, 0) 100%); */
    /* top: 0px;
    */
    /* bottom: 0px;
    */
    /* left: 0px;
    */
    width: 100%;
    height: 100%;
    /* right: 0px;
    */
}

.hot-page2-alp-tit h1 {
    display: inline-block;
    color: #fff;
}

.hot-page2-alp-tit ol {
    background: none;
}

.hot-page2-alp-tit ol li a {
    color: #8a8a8a;
}

.breadcrumb>.active {
    color: #ffc72f !important;
}

.breadcrumb>li+li:before {
    color: #8a8a8a;
}

.hot-page2-alp-con {
    position: relative;
    overflow: hidden;
    background: #f2f1f1;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.36);
}

.hot-page2-alp-con-left {
    padding-left: 0px !important;
}

.hot-page2-alp-con-right {
    padding-bottom: 70px;
}

.hot-page2-alp-con-left-1 {
    background: #253d52;
    color: #fff;
    padding: 25px;
}

.hot-page2-alp-con-left-1:after,
.hot-page2-alp-con-left-1:before {
    top: 72px;
    left: 48%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.hot-page2-alp-con-left-1:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #1f4363;
    border-width: 9px;
    margin-left: -9px;
}

.hot-page2-alp-con-left-1 h3 {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.hot-page2-alp-cl-1-1 img {
    width: 24px;
    height: 24px;
}

.hot-page2-alp-cl-1-2 {
    padding-left: 15px;
}

.hot-page2-alp-cl-1-2 h5 {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.hot-page2-hom-pre ul li:hover .hot-page2-hom-pre-2 h5 {
    padding-left: 5px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.hot-page2-alp-cl-1-2 span {
    line-height: 12px;
    font-size: 12px;
}

.hot-page2-alp-cl-1-3 img {
    width: 18px;
    float: right;
}

.hot-page2-alp-left-ner-notb {
    border-top: 0px;
    margin-bottom: 0px;
}

.hot-page2-alp-left-ner-notb ul li {
    padding: 10px 0px;
}

.hot-page2-alp-l3 ul {
    padding: 0px;
}

.hot-page2-alp-l3 ul li {
    list-style-type: none;
    border-bottom: 1px solid #d4d4d4;
}

.hot-page2-alp-l3 ul li:last-child {
    border-bottom: 0px solid #d4d4d4;
}

.hot-page2-alp-l3 ul li label {
    display: block;
    font-size: 15px;
    color: #343c42;
    font-weight: 600;
    padding-left: 30px;
}

.hot-page2-alp-l-com h4 {
    background: #e6e6e6;
    color: #343c42;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 15px;
    margin-top: 0px;
    margin-bottom: 0px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.hot-page2-alp-l-com h4 i {
    padding-right: 7px;
}

.hot-page2-alp-l-com1 form {
    padding-bottom: 8px;
}

.hot-page2-alp-l-com1 {
    padding: 15px;
    background: #ffffff;
    padding-bottom: 25px;
}

.hot-page2-alp-l-com1 a {
    margin-top: 5px;
    border: 1px solid #254158;
    /* -webkit-box-shadow: #B7B8B8 0px 1px 0px inset;
    */
    -moz-box-shadow: #B7B8B8 0px 1px 0px inset;
    /* box-shadow: #B7B8B8 0px 1px 0px inset;
    */
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    padding: 5px 10px;
    text-decoration: none;
    text-shadow: -1px -1px 0 rgba(177, 177, 177, 0.3);
    font-weight: bold;
    color: #ffffff;
    background-color: #304b62;
    background-image: -webkit-linear-gradient(to bottom, #3b5871, #253d52);
    background-image: -moz-linear-gradient(to bottom, #3b5871, #253d52);
    background-image: -ms-linear-gradient(to bottom, #3b5871, #253d52);
    background-image: -o-linear-gradient(to bottom, #3b5871, #253d52);
    background-image: linear-gradient(to bottom, #3b5871, #253d52);
    margin-right: 5px;
}

.hot-page2-alp-l-com1 a:hover {
    color: #fff;
}

.hot-page2-alp-l-com1 a i {
    font-size: 10px;
}

.hot-page2-alp-p3 ul li:nth-child(1n+6) {
    display: none;
}

.hot-page2-alp-p3-btn-h {
    display: none;
}

.hot-page2-alp-p4-btn-h {
    display: none;
}

.hot-page2-alp-p4 ul li:nth-child(1n+6) {
    display: none;
}

.hot-page2-alp-p5-btn-h {
    display: none;
}

.hot-page2-alp-p5 ul li:nth-child(1n+6) {
    display: none;
}

/*-------------------------------------------------*/
/* LISTING 
/*-------------------------------------------------*/
.hot-page2-lp-rat {
    padding: 6px 6px 15px 8px;
}

.hot-page2-alp-ri-p2 {
    padding: 18px 15px;
}

.hot-page2-alp-ri-p2 h3 {
    color: #343c42;
    font-size: 22px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.hot-page2-alp-ri-p2 ul {
    padding: 0px;
    position: relative;
    overflow: hidden;
    padding-top: 12px;
    margin-bottom: 4px;
}

.hot-page2-alp-ri-p2 ul li {
    list-style-type: none;
    float: left;
    margin-bottom: 8px;
    font-size: 14px;
    padding-left: 30px;
}

.hot-page2-alp-ri-p2 ul li:nth-child(1) {
    color: #343c42;
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center left;
    background-size: 18px;
    font-size: 15px;
    font-weight: 600;
}

.hot-page2-alp-ri-p2 ul li:nth-child(2) {
    color: #979797;
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center left;
    background-size: 18px;
}

.hot-page2-alp-ri-p3 {
    padding: 52px 0px 10px 0px;
}

.hot-page2-alp-ri-p3 span {
    display: block;
    text-align: center;
}

.hot-list-p3-1 {
    color: #979797;
    font-weight: 400;
    font-size: 14px;
}

.hot-list-p3-2 {
    font-size: 32px;
    font-weight: 600;
    color: #f4364f;
    padding: 10px 0px;
}

.hot-list-p3-3 {
    font-size: 24px;
    color: #979797;
    text-decoration: line-through;
    padding: 10px 0px;
}

.hot-list-p3-4 a {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.hot-page2-alp-ri-p2 p {
    color: #979797;
    /* padding-bottom: 12px;
    */
    margin-bottom: 0px;
    text-transform: lowercase;
    font-size: 14px;
}

.hot-page2-alp-ri-p2 p b {
    padding-right: 5px;
    color: #343c42;
    text-transform: uppercase;
    font-size: 12px;
}

.hot-page2-alp-ri-p2 a:hover {
    color: #fff;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.hot-page2-alp-quot-btn {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    margin-top: 5px;
    border: 1px solid #e5ad05;
    border: 1px solid #d7a307;
    /* -webkit-box-shadow: #B7B8B8 0px 1px 0px inset;
    */
    -moz-box-shadow: #B7B8B8 0px 1px 0px inset;
    /* box-shadow: #B7B8B8 0px 1px 0px inset;
    */
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 14px;
    padding: 5px 10px;
    text-decoration: none;
    text-shadow: -1px -1px 0 rgba(177, 177, 177, 0.3);
    font-weight: bold;
    color: #000000;
    background-color: #ffd65e !important;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd65e), to(#febf04));
    background-image: -webkit-linear-gradient(top, #ffd65e, #febf04);
    background-image: -moz-linear-gradient(top, #ffd65e, #febf04);
    background-image: -ms-linear-gradient(top, #ffd65e, #febf04);
    background-image: -o-linear-gradient(top, #ffd65e, #febf04);
    background-image: linear-gradient(to bottom, #ffd65e, #febf04);
    display: block;
    text-align: center;
}

.hot-page2-alp-quot-free-btn {
    margin-top: 5px;
    border: 1px solid #234767;
    /* -webkit-box-shadow: #B7B8B8 0px 1px 0px inset;
    */
    -moz-box-shadow: #B7B8B8 0px 1px 0px inset;
    /* box-shadow: #B7B8B8 0px 1px 0px inset;
    */
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    padding: 5px 10px;
    text-decoration: none;
    text-shadow: -1px -1px 0 rgba(177, 177, 177, 0.3);
    font-weight: bold;
    color: #ffffff;
    background-color: #1f4363;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ffd65e), to(#febf04));
    background-image: -webkit-linear-gradient(to bottom, #32638e, #1f4363);
    background-image: -moz-linear-gradient(top, #ffd65e, #febf04);
    background-image: -ms-linear-gradient(top, #ffd65e, #febf04);
    background-image: -o-linear-gradient(top, #ffd65e, #febf04);
    background-image: linear-gradient(to bottom, #32638e, #1f4363);
    margin-right: 5px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.hot-ameni {
    margin-bottom: -15px;
}

.hot-ameni ul {
    padding: 0px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0px;
}

.hot-ameni ul li {
    list-style-type: none;
    float: left;
    width: 33.33%;
    padding-bottom: 15px;
    padding-right: 15px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
}

.hot-ameni ul li i {
    font-size: 12px;
    width: 22px;
    height: 22px;
    background: #ffcd33;
    color: #000;
    text-align: center;
    padding: 5px;
    border-radius: 50%;
    margin-right: 6px;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.62);
}

.hot-page2-alp-r-list-re-sp {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.hot-page2-alp-r-list {
    border: 1px solid #dedede;
    background: #fff;
    position: relative;
    overflow: hidden;
    padding: 0 10px;
    margin: 15px 0;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.hot-page2-alp-r-list {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.hot-page2-alp-r-list:hover {
    box-shadow: 0px 5px 19px -3px rgba(0, 0, 0, 0.23);
    transform: scale(1.02);
}

.hot-page2-alp-r-list:hover .hot-page2-alp-quot-btn {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    /* border: 1px solid #02abc1;
    */
    text-shadow: -1px -1px 0 rgba(177, 177, 177, 0.3);
    color: #000000;
    background-color: #fff;
    background-image: -webkit-linear-gradient(to bottom, #00bcd4, #05a6bb);
    background-image: -moz-linear-gradient(to bottom, #00bcd4, #05a6bb);
    background-image: -ms-linear-gradient(to bottom, #00bcd4, #05a6bb);
    background-image: -o-linear-gradient(to bottom, #00bcd4, #05a6bb);
    /* background-image: linear-gradient(to bottom, #00bcd4, #05a6bb);
    */
}

.hot-page2-alp-r-hot-page-rat {
    background: #253d52;
    position: absolute;
    padding: 4px 8px;
    right: -1px;
    border-radius: 1px;
    color: #fff;
    top: 0px;
    font-weight: 600;
    font-size: 12px;
}

.hotel-list-score {
    width: 32px;
    height: 32px;
    position: absolute;
    background-color: #f4364fba;
    /* bottom: 8px;
    */
    padding: 4px;
    margin: 10px 5px;
    right: 5px;
    border-radius: 2px;
    z-index: 9;
    color: #fff;
    font-weight: 600;
    text-align: center;
    text-shadow: 0px -1px 0 rgba(0, 0, 0, 0.28);
}

/*--============== alter =====================--*/
.hot-page2-hr1 {
    text-align: center;
    width: 70%;
    margin: 0 auto;
    position: relative;
}

.hot-page2-hli {
    position: relative;
    overflow: hidden;
}

.hot-page2-hli a {
    text-decoration: none;
}

.hot-page2-hli ul {
    padding: 0px;
}

.hot-page2-hli ul li {
    list-style-type: none;
}

.hot-page2-hli-1 {
    position: relative;
}

.hot-page2-hli-1:before {
    content: '';
    position: absolute;
    background: linear-gradient(to top, rgba(244, 54, 79, 0.36) 25%, rgba(0, 0, 0, 0.08) 74%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.hot-page2-hli-1 img {
    width: 100%;
}

.hot-page2-hli-3 {
    width: 35px;
    height: 35px;
    position: absolute;
    background-color: rgba(255, 111, 111, 0.68);
    /* bottom: 8px;
    */
    padding: 4px;
    margin: auto;
    right: 15px;
    top: 15px;
    border-radius: 2px;
    display: none;
}

.hot-page2-hli-5 {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    margin-bottom: 10px;
}

.hot-page2-hli-2 {
    padding: 10px 12px;
    background: #ffffff;
}

.hot-page2-hli-2 h4 {
    color: #273440;
    font-size: 16px;
}

.hot-page2-hli-2 h4 span {
    float: right;
    font-size: 12px;
    line-height: 20px;
}

.hot-page2-hpb {
    background: #1b2629;
}

.hot-page2-hpb h2 {
    color: #fff;
}

.hot-page2-hpb h5 {
    color: #ffc520;
    padding-top: 14px;
    font-weight: 400;
}

.hot-page2-hpb p {
    color: #989898;
    padding-top: 15px;
    margin-bottom: 0px;
}

.hot-page2-hpb ul {
    margin-bottom: 0px;
    padding: 0px;
    padding-top: 42px;
}

.hot-page2-hpb ul li {
    list-style-type: none;
    display: inline-block;
    width: 32%;
    float: left;
    margin: 0px 3px;
    margin-bottom: 5px;
}

.hot-page2-hpb ul li a {
    display: block;
    background: #ffc520;
    color: #000;
    padding: 14px 20px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    border-radius: 2px;
}

/*-------------------------------------------------*/
/* ROOM AVAILABILITY CHECK 
/*-------------------------------------------------*/
.hot-room-ava-check form {
    padding-bottom: 0px;
}

.hot-room-ava-check ul {
    margin-bottom: 0px;
}

.hot-room-ava-check ul li {
    border-bottom: 0px;
}

.hot-room-ava-check ul li label {
    padding-left: 0px;
}

.hot-room-ava-check ul li input {
    width: 100%;
    font-size: 13px;
    padding: 4px 10px;
    border: 1px solid #d4d4d4;
    margin-bottom: 12px;
}

.hot-room-ava-check ul li input[type="submit"] {
    margin-bottom: 0px;
    /* margin-top: 5px;
    */
    border: 1px solid #254158;
    /* -webkit-box-shadow: #B7B8B8 0px 1px 0px inset;
    */
    -moz-box-shadow: #B7B8B8 0px 1px 0px inset;
    /* box-shadow: #B7B8B8 0px 1px 0px inset;
    */
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-size: 12px;
    font-family: arial, helvetica, sans-serif;
    padding: 5px 10px;
    text-decoration: none;
    text-shadow: -1px -1px 0 rgba(177, 177, 177, 0.3);
    font-weight: bold;
    color: #ffffff;
    background-color: #304b62 !important;
    background-image: -webkit-linear-gradient(to bottom, #3b5871, #253d52);
    background-image: -moz-linear-gradient(to bottom, #3b5871, #253d52);
    background-image: -ms-linear-gradient(to bottom, #3b5871, #253d52);
    background-image: -o-linear-gradient(to bottom, #3b5871, #253d52);
    background-image: linear-gradient(to bottom, #3b5871, #253d52);
    /* margin-right: 5px;
    */
}

.tourb2-hli-3 {
    width: 32px;
    height: 32px;
    position: absolute;
    background-color: rgba(255, 193, 7, 0.84);
    /* bottom: 8px;
    */
    padding: 4px;
    /* margin: 10px 5px;
    */
    right: 15px;
    border-radius: 2px;
    z-index: 9;
    color: #fff;
    font-weight: 600;
    text-align: center;
    text-shadow: 0px -1px 0 rgba(0, 0, 0, 0.28);
    top: 15px;
}

/*-------------------------------------------------*/
/* SOCIAL MEDIA SHARE 
/*-------------------------------------------------*/
.icon-float {
    position: fixed;
    left: 0px;
    top: 35%;
    z-index: 999;
}

.icon-float ul {
    padding: 0px;
}

.icon-float ul li {
    list-style-type: none;
}

.icon-float ul li a {
    display: block;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 5px;
    text-align: center;
}

.icon-float ul li a i {
    padding: 8px;
    width: 40px;
    height: 32px;
    text-align: center;
    color: #fff;
}

.icon-float ul li a:hover {
    transform: rotateY(-180deg);
}

.icon-float ul li a:hover i {
    transform: rotateY(180deg);
}

.fb1 {
    background: #3b5998 !important;
}

.gp1 {
    background: #f4364f !important;
}

.tw1 {
    background: #1da1f2 !important;
}

.li1 {
    background: #0077b5 !important;
}

.yt1 {
    background: #cd201f !important;
}

.wa1 {
    background: #34af23 !important;
}

.sh {
    background: #fff !important;
    padding: 10px 0px;
    font-size: 11px;
    text-align: center;
    color: #4d90fe !important;
    border-top-right-radius: 5px;
}

.sh1 {
    background: #4d90fe !important;
    border-bottom-right-radius: 5px;
}

/*-------------------------------------------------*/
/* MY ACCOUNT DROP DOWN 
/*-------------------------------------------------*/
.top-menu {
    min-width: 200px;
}

/*-------------------------------------------------*/
/* SECTION AND DIV - TOP AND BOTTOM EXTRA SPACING 
/*-------------------------------------------------*/
.pad-bot-redu {
    padding-bottom: 70px;
}

.pad-bot-redu-5 {
    padding-bottom: 50px;
}

.pad-bot-incre {
    padding-bottom: 100px;
}

.pad-left-o {
    padding-left: 0px !important;
}

.pad-top-o {
    padding-top: 0px;
}

/*-------------------------------------------------*/
/* HOME PAGE CITY HOTELS 
/*-------------------------------------------------*/
.tour-mig-like-com {
    position: relative;
    border-radius: 10px;
    margin-bottom: 20px;
    background: #14addb;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.tour-mig-like-com:hover img {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0.5;
}

.tour-mig-lc-img img {
    width: 100%;
    border-radius: 5px;
}

.tour-mig-like-com:before {
    content: '';
    position: absolute;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.82) 10%, rgba(84, 84, 84, 0.35) 80%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-radius: 5px;
}

.tour-mig-lc-con {
    position: absolute;
    width: 100%;
    margin-top: -125px;
    padding: 20px 20px 0px 20px;
    display: block;
    text-align: right;
}

.tour-mig-lc-con2 {
    margin-top: -100px;
}

.tour-mig-lc-con h5 {
    font-size: 26px;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 2px;
}

.tour-mig-lc-con h6 {
    font-size: 14px;
    color: #fff;
    position: absolute;
    top: -20px;
    /* right: 0px;
    */
    border: 1px solid #ffffff;
    padding: 8px 10px 3px 10px;
    border-radius: 18px;
    margin-bottom: 5px;
    padding-bottom: 10px;
}

.tour-mig-lc-con p {
    color: #c1c1c1;
}

.tour-mig-lc-con p span {
    text-align: left;
    float: left;
    font-size: 20px;
    color: #eee;
}

/*-------------------------------------------------*/
/* HOME PAGE FOOTER APP 
/*-------------------------------------------------*/
.foot-mob-app h2 {
    margin-top: 0px;
    font-size: 45px;
}

.foot-mob-app p {
    font-size: 15px;
}

.foot-mob-app ul {
    padding: 0px;
    margin-bottom: 30px;
    margin-top: 24px;
}

.foot-mob-app ul li {
    list-style-type: none;
    color: #4d90fe;
    line-height: 38px;
    font-size: 18px;
}

.foot-mob-app ul li svg {
    color: #fff;
    width: 18px;
    height: 18px;
    background: #8dced4;
    border-radius: 3px;
    text-align: center;
    padding: 3px;
    margin-right: 10px;
    font-size: 12px;
    vertical-align: text-bottom;
}

.foot-mob-sec {
    background: url(/whitebg.jpg) no-repeat bottom center #f6f6f6;
    padding: 100px 0px 60px 0px;
    background-size: contain;
    position: relative;
}

.foot-mob-sec:before {
    content: '';
    position: absolute;
    background: linear-gradient(to top, rgba(255, 255, 255, 0.55) 0%, rgba(240, 240, 240, 0.8) 100%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

/*-------------------------------------------------*/
/* HOME PAGE SEARCH BOX 
/*-------------------------------------------------*/
.tourz-search {
    background: url(/listing/home3.jpg) no-repeat;
    position: relative;
    background-size: cover;
    background-position-y: center;
}

#select-city {
    background: #fff;
    border: 0px;
    height: 45px;
    border-radius: 2px;
    padding: 0px 10px;
    box-sizing: border-box;
    font-size: 14px;
    border: 1px solid #efefef;
}

#select-search {
    background: url(/search.png) no-repeat left center #fff;
    border: 0px;
    height: 45px;
    border-radius: 2px;
    padding: 0px 10px 0px 35px;
    box-sizing: border-box;
    font-size: 14px;
    background-size: 17px;
    background-position-x: 10px;
}

.search-hotel-type {
    padding-left: 18px;
}

.tourz-search-form input[type="submit"] {
    background: none !important;
    width: 100%;
}

.tourz-search-1 {
    padding: 150px 0px 100px 0px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

.tourz-search-1 h1 {
    color: #fff;
    font-size: 50px;
}

.tourz-search-1 p {
    font-size: 18px;
    color: #fff;
    padding-bottom: 30px;
    margin-bottom: 0px;
}

.tourz-search-form label {
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    right: 15px;
}

.tourz-search-form div:nth-child(1) {
    width: 20%;
    float: left;
}

.tourz-search-form div:nth-child(2) {
    width: 60%;
    float: left;
}

.tourz-search-form div:nth-child(3) {
    width: 15%;
    float: left;
}

.tourz-search:before {
    /* content: '';
    */
    background: #141E30;
    background: linear-gradient(to right, rgba(36, 59, 85, 0.55), rgba(20, 30, 48, 0.82));
    background: linear-gradient(to right, rgba(36, 59, 85, 0.55), rgba(20, 30, 48, 0.82));
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
}

.tourz-search-form div {
    margin: 0px 5px;
}

.tourz-sear-btn {
    height: 45px;
    line-height: 45px;
    background: #f4364f;
    background: linear-gradient(to bottom, #f4364f, #dc2039);
    outline: none;
    font-size: 16px;
    display: block !important;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;
}

.tourz-hom-ser {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 38px;
    padding-bottom: 5px;
}

.tourz-hom-ser ul {
    margin-bottom: 0px;
    margin: 0 auto;
    display: table;
    padding: 0px;
}

.tourz-hom-ser ul li {
    float: left;
    display: inline;
    text-align: center;
    width: 25%;
    padding: 0px 5px;
}

.tourz-hom-ser ul li a {
    background: #e8095a00;
    width: 90px;
    height: 90px;
    display: block;
    padding: 18px 2px 10px 2px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
    font-family: 'Arimo', sans-serif;
    /* position: relative;
    */
    /* overflow: hidden;
    */
    line-height: 24px;
    border: 1px solid #8e8e8e;
}

.tourz-hom-ser ul li a:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    background: #f4364f;
    background: linear-gradient(to bottom, #f4364f, #dc2039);
    border: 1px solid #f4364f;
}

.tourz-hom-ser ul li a img {
    display: table;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 5px;
    width: 36px;
}

.tourz-top-phone {
    border: 1px solid #9a729a;
    border-radius: 50px;
}

.autocomplete-content ul li a {
    border-bottom: 1px solid #e0e0e0;
}

.profile-btn {
    border: none;
    height: 30px;
    line-height: 26px;
    color: #fff;
    padding: 2px 10px;
    border-radius: 2px;
    font-family: 'Quicksand', sans-serif;
    background: #e8095a;
    background: linear-gradient(to bottom, #e8095a, #d40852);
}

.profile-btn:hover {
    color: #fff;
}

.autocomplete-content li img {
    float: left;
    width: 26px !important;
    height: 26px !important;
    left: 0px;
    top: 15px;
}

/*-------------------------------------------------*/
/* MOBILE MENU 
/*-------------------------------------------------*/
.mob-menu-icon {
    display: inline-block;
    color: #fff;
    background: #f66365;
    padding: 5px 8px;
    cursor: pointer;
    font-size: 18px;
    border-radius: 2px;
}

.mob-menu-slide {
    z-index: 999;
    background: #ffffff;
    right: -240px;
    padding: 0px;
    border-radius: 2px;
    height: 100%;
    position: fixed;
    top: 0px;
    box-shadow: 10px 50px 100px rgba(0, 0, 0, 0.8);
    z-index: 9999;
    overflow-y: scroll;
}

.mob-menu-slide h4 {
    padding: 0px 15px 10px 15px;
    border-bottom: 0px solid #303b40;
    margin-bottom: 0px;
    color: #f76466;
    margin-top: 30px;
}

.mob-menu-slide ul {
    padding: 0px;
    text-align: left;
    position: relative;
    overflow: hidden;
}

.mob-menu-slide ul li {
    list-style-type: none;
    text-align: left;
    display: block;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #ececec;
}

.mob-menu-slide ul li a {
    color: #000;
    float: inherit;
    display: block;
    font-size: 13px;
    /* border-bottom: 1px solid #303b40;
    */
    padding: 12px 20px;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Quicksand', sans-serif;
}

.mob-menu {
    background: #253d52;
    position: fixed;
    z-index: 99;
    width: 100%;
    display: none;
    padding: 10px 30px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.63);
}

.mob-head-left {
    width: 50%;
    float: left;
    display: block;
}

.mob-head-right {
    width: 50%;
    float: left;
    display: block;
}

.mob-head-right a {
    float: right;
}

.top-menu a i {
    padding-right: 10px;
}

.btn-close-menu {
    display: none;
    z-index: 999;
    background: #f66365;
    color: #fff;
    position: absolute;
    right: 185px;
    padding: 4px;
    border-radius: 42px;
    top: 5px;
    z-index: 99999;
    width: 32px;
    height: 32px;
    text-align: center;
}

/*-------------------------------------------------------*/
/* DASHBOARD 
/*-------------------------------------------------------*/
.db {
    position: relative;
    overflow: hidden;
    width: 100%;
    background: #eaedef;
    /* margin-top: 100px;
    */
    padding: 50px;
}

.db-l {
    float: left;
    width: 20%;
}

.db-2 {
    float: left;
    width: 58%;
    position: relative;
    overflow: hidden;
    background: #fff;
    border: 1px solid #dadada;
    /* margin-bottom: 25px;
    */
    box-shadow: 0px 2px 4px rgba(224, 224, 224, 0.8);
    border-radius: 4px;
    margin-left: 12px;
    margin-right: 12px;
}

.db-3 {
    float: left;
    width: 20%;
    position: relative;
    overflow: hidden;
    background: #fff;
    border: 1px solid #dadada;
    /* margin-bottom: 25px;
    */
    box-shadow: 0px 2px 4px rgba(224, 224, 224, 0.8);
    border-radius: 4px;
}

.db-l-1 {
    position: relative;
    overflow: hidden;
    background: #fff;
    border: 1px solid #dadada;
    /* margin-bottom: 25px;
    */
    box-shadow: 0px 2px 4px rgba(224, 224, 224, 0.8);
    border-radius: 4px;
}

.db-l-1 ul {
    padding: 0px;
}

.db-l-1 ul li {
    color: #adadad;
    list-style-type: none;
    text-align: center;
}

.db-l-1 ul li span {
    display: block;
    font-size: 32px;
    font-weight: 500;
    line-height: 30px;
    color: #21252b;
}

.db-l-1 ul li:nth-child(1) {
    width: 100%;
    float: left;
}

.db-l-1 ul li:nth-child(1) img {
    width: 100%;
}

.db-l-1 ul li:nth-child(2) {
    width: 50%;
    float: left;
    padding: 20px 10px;
    /* text-align: center;
    */
}

.db-l-1 ul li:nth-child(3) {
    width: 50%;
    float: left;
    padding: 20px 10px;
}

.db-l-2 {
    position: relative;
    overflow: hidden;
    background: #fff;
    border: 1px solid #dadada;
    padding: 20px;
    margin-top: 12px;
    box-shadow: 0px 2px 4px rgba(224, 224, 224, 0.8);
    border-radius: 4px;
}

.db-l-2 ul {
    padding: 0px;
}

.db-l-2 ul li {
    border-bottom: 1px solid #eaedef;
    padding: 12px 0px;
    list-style-type: none;
}

.db-l-2 ul li:last-child {
    border-bottom: 0px solid #dcdcdc;
    padding: 12px 0px 0px 0px;
}

.db-l-2 ul li a {
    color: #4d90fe;
}

.db-l-2 ul li a:hover {
    color: #4d90fe;
    padding-left: 8px;
}

.db-l-2 ul li a img {
    width: 20px;
    margin-right: 8px;
}

.db-3 h4 {
    margin: 0px;
    background: #253d52;
    padding: 15px;
    color: #fff;
}

.db-3 ul {
    margin-bottom: 0px;
    padding: 20px;
    padding-left: 20px !important;
}

.db-3 ul li {
    border-bottom: 1px solid #dcdcdc;
    padding: 12px 0px;
    list-style-type: none;
}

.db-3 ul li a img {
    float: left;
    width: 28px;
    height: 28px;
    border-radius: 50px;
    margin-right: 10px;
}

.db-3 ul li a h5 {
    color: #4d90fe;
    font-size: 15px;
    margin-top: 0px;
    text-overflow: ellipsis;
    white-space: pre;
    overflow: hidden;
    text-transform: capitalize;
    margin-bottom: 0px;
}

.db-3 ul li a p {
    padding-left: 40px;
    margin-bottom: 0px;
    color: #969595;
    font-size: 12px;
    line-height: 18px;
}

.db-3 ul li:last-child {
    border-bottom: 0px solid #dcdcdc;
    padding: 12px 0px 0px 0px;
}

.db-2-com h4 {
    margin: 0px;
    background: #253d52;
    padding: 15px;
    color: #fff;
}

.db-2-main-com {
    padding: 20px;
    position: relative;
    overflow: hidden;
}

.db-2-main-com img {
    display: block;
    /* width: 80px;
    */
    margin: 0 auto;
    /* padding-bottom: 20px;
    */
    border: 1px solid #eaedef;
    padding: 12px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.db-2-main-com span {
    font-size: 24px;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.db-2-main-com ul {
    margin-bottom: 0px;
    /* border-top: 1px solid #eaedef;
    */
    margin-top: 10px;
    padding: 0px;
}

.db-2-main-com ul li {
    text-align: left;
    border-bottom: 1px solid #eaedef;
    padding: 12px 0px;
    list-style-type: none;
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center left;
    background-size: 10px;
    text-overflow: ellipsis;
    white-space: inherit;
    overflow: hidden;
}

.db-2-main-com ul li a {
    /* text-align: left;
    */
    display: block;
    color: #4d90fe;
    font-size: 13.5px;
    padding-left: 14px;
}

.db-2-main-com ul li a:hover {
    padding-left: 10px;
}

.db-done {
    font-size: 12px !important;
    background: #3e8e41;
    color: #fff;
    padding: 2px 4px;
    border-radius: 3px;
    text-transform: lowercase;
    margin-left: 5px;
}

.db-done:hover {
    color: #fff;
}

.db-not-done {
    font-size: 12px !important;
    background: #f4a321;
    color: #fff;
    padding: 2px 4px;
    border-radius: 3px;
    text-transform: lowercase;
    margin-left: 5px;
}

.db-2-main-1 {
    position: relative;
    overflow: hidden;
    float: left;
    width: 33.3%;
    text-align: center;
}

.db-2-main-2 {
    border-right: 1px solid #eaedef;
    padding: 20px;
}

.db-2-main-com-table th {
    font-weight: 600;
    padding: 10px 4px;
}

.db-2-main-com-table tr {
    border-bottom: 1px solid #eaedef;
    line-height: 37px;
    padding: 10px;
}

.db-2-main-com-table tr td {
    padding: 16px 4px 13px 4px;
}

.db2-form-com form {
    padding: 35px 50px 35px 50px;
    background: #eaedef;
    margin-top: 15px;
}

.db2-form-com form input {
    border: 1px solid #e4e4e4;
    line-height: 44px;
    padding: 0px 25px;
    background: #fff;
}

.db2-form-com form input[type="submit"] {
    padding: 0px;
}

.db2-form-com form label {
    font-size: 14px;
    color: #646464;
}

.full-btn {
    height: 45px;
    line-height: 45px;
    background: #f96c71;
    background: linear-gradient(to bottom, #fa6d72, #f45d5d);
    outline: none;
    font-size: 16px;
    display: block !important;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;
    text-align: center;
}

.db-pay-card {
    position: relative;
    overflow: hidden;
    border: 1px solid #eaedef;
    padding: 1px 10px;
}

.db-pay-card h5 {
    display: inline-block;
}

.db-pay-card img {
    width: 175px;
    border: 0px;
    display: inline-block;
    margin-bottom: 0px;
    float: right;
    padding: 0px;
}

.db2-form-pay ul li {
    background: none;
    padding: 5px 0px 12px 0px;
}

.db-pay-amount {
    float: right;
    color: #FFC107;
    font-size: 22px;
}

.db-down-pdf {
    border: 1px solid #c7c8c9;
    border-radius: 3px;
    padding: 4px 8px;
    color: #4d90fe;
}

.db-make-pay {
    border: 1px solid #c7c8c9;
    border-radius: 3px;
    padding: 4px 8px;
    color: #4d90fe;
}

.db-make-pay:hover {
    background: #3e8e41;
    color: #fff;
    border: 1px solid #317d34;
}

.db-mak-pay-bot {
    margin-top: 25px;
}

.db-mak-pay-bot p {
    color: #969595;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
}

.db-mak-pay-bot a {
    font-size: 16px;
    text-transform: capitalize;
}

.db-mak-pay-bot a:hover {
    color: #fff;
}

.db-up-btn {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    float: left;
    height: 3rem;
    line-height: 3rem;
    height: 45px;
    padding: 10px 25px;
    background: #14addb;
    color: #fff;
}

.db-file-upload {
    padding: 0 0.75rem;
}

.db-com-p h5 {
    font-size: 20px;
}

.db-com-p p {
    color: #969595;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
}

#dropdown1 a {
    color: #4d90fe;
}

/*-------------------------------------------------------*/
/* REGISTER AND LOGIN 
/*-------------------------------------------------------*/
.tr-register {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) #e6e6e6;
    position: relative;
    /* overflow: hidden;
    */
    display: inline-block;
    width: 100%;
}

.tr-register::before {
    content: '';
    position: absolute;
    background: rgba(33, 48, 59, 0.52);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    border-radius: 5px;
}

.tr-regi-form {
    margin: 0 auto;
    width: 50%;
    background: #fff;
    padding: 45px 50px 50px 50px;
    margin-top: 70px;
    margin-bottom: 70px;
    border-radius: 5px;
    text-align: center;
    position: relative;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.8);
}

.tr-regi-form input {
    padding: 10px 10px 10px;
    border: 1px solid #c9c9c9;
}

.tr-regi-form textarea {
    padding: 10px 10px 10px 22px;
    border: 1px solid #c9c9c9;
    height: 100px;
}

.tr-regi-form input[type="submit"] {
    padding: 0px;
    font-size: 18px !important;
    width: 100%;
}

.tr-regi-form h4 {
    margin-top: 0px;
    font-size: 42px;
    margin-bottom: 8px;
}

.tr-regi-form h4 span {
    color: #e92b44;
    font-size: 42px;
    font-weight: 600;
}

.tr-regi-form form {
    position: relative;
    padding: 20px;
}

.tr-regi-form p {
    font-size: 18px;
    margin-bottom: 0px;
}

.full-btn {
    width: 100%;
    display: block;
}

.soc-login {
    position: relative;
    overflow: hidden;
    border-top: 1px solid #dcdcdc;
    margin-top: 15px;
    padding-top: 15px;
}

.soc-login h4 {
    text-align: center;
    padding-bottom: 15px;
}

.soc-login ul {
    padding: 0px;
    margin: 0px;
}

.soc-login ul li {
    list-style-type: none;
    float: left;
    width: 33.333%;
    padding: 5px;
}

.soc-login ul li a {
    display: block;
    color: #fff;
    padding: 7px 18px;
    text-align: center;
    border-radius: 2px;
}

.soc-login ul li:nth-child(1) a {
    background: #3b5998;
}

.soc-login ul li:nth-child(2) a {
    background: #00aced;
}

.soc-login ul li:nth-child(3) a {
    background: #d34836;
}

.soc-login ul li a i {
    padding-right: 5px;
}

/*-------------------------------------------------------*/
/* VERSION 2: HOME SEARCH BOX 
/*-------------------------------------------------------*/
.v2-ho-se-ri h5 {
    color: #fff;
    text-transform: uppercase;
    font-family: 'Quicksand', sans-serif;
    font-size: 10px;
    letter-spacing: 4px;
    font-weight: 100;
}

.v2-ho-se-ri h1 {
    margin: 0px;
    color: #fff;
    font-size: 48px;
    margin-bottom: 20px;
}

.v2-ho-se-ri p {
    color: #fff;
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 100;
    font-family: 'Quicksand', sans-serif;
    line-height: 30px;
}

.v2-search-form {
    position: relative;
    background: #ffffff;
    padding: 50px;
    border-radius: 5px;
    border-bottom: 2px solid #cccccc;
    box-shadow: 0px 11px 26px 0px rgba(0, 0, 0, 0.29);
}

.v2-search-form label {
    color: #727070;
    font-weight: 600;
    top: 14px;
    font-size: 14px;
}

.v2-search-form input {
    background: #fff;
    border: 0px;
    height: 45px;
    border-radius: 2px;
    padding: 0px 10px;
    box-sizing: border-box;
    font-size: 14px;
    border: 1px solid #efefef;
}

.v2-hom-search {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat;
    position: relative;
    background-size: cover;
    padding: 100px 0px;
}

.v2-hom-search:before {
    content: '';
    background: #141E30;
    background: linear-gradient(to right, rgba(36, 59, 85, 0.55), rgba(20, 30, 48, 0.82));
    background: linear-gradient(to right, rgba(25, 42, 103, 0.8), rgba(20, 30, 48, 0));
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
}

.v2-ser-btn {
    text-align: center;
}

.v2-hom-ser {
    padding-top: 28px;
}

.v2-hom-ser ul {
    margin: inherit;
}

/*-------------------------------------------------*/
/* = DATE PICKER 
/*-------------------------------------------------*/
.ui-state-disabled {
    cursor: default !important;
    pointer-events: none;
}

.ui-datepicker-prev {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center;
    background-size: 12px;
}

.ui-datepicker-next {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center;
    background-size: 12px;
}

/* Icons ----------------------------------*/
.ui-icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -.25em;
    position: relative;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}

.ui-widget-icon-block {
    left: 50%;
    margin-left: -8px;
    display: block;
}

/* Misc visuals ----------------------------------*/
.ui-datepicker {
    padding: .2em .2em 0;
    display: none;
}

.ui-datepicker .ui-datepicker-header {
    position: relative;
    padding: .2em 0;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: -4px;
    width: 1.8em;
    height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover {
    top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
    left: 2px;
}

.ui-datepicker .ui-datepicker-next {
    right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
    left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover {
    right: 1px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
    display: block;
    position: absolute;
    left: 50%;
    margin-left: -8px;
    top: 50%;
    margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
    font-size: 1em;
    margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    width: 45%;
}

.ui-datepicker table {
    width: 100%;
    font-size: .9em;
    border-collapse: collapse;
    margin: 0 0 .4em;
}

.ui-datepicker th {
    padding: .7em .3em;
    text-align: center;
    font-weight: 500;
    color: #aaaaaa;
    border: 0;
}

.ui-datepicker td {
    border: 0;
    padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
    display: block;
    padding: 9px;
    text-align: center;
    text-decoration: none;
    width: 40px;
    height: 40px;
}

.ui-datepicker .ui-datepicker-buttonpane {
    background-image: none;
    margin: .7em 0 0 0;
    padding: 0 .2em;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
    float: right;
    margin: .5em .2em .4em;
    cursor: pointer;
    padding: .2em .6em .3em .6em;
    width: auto;
    overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
    float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
    width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
    float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
    width: 95%;
    margin: 0 auto .4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
    border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
    clear: left;
}

.ui-datepicker-row-break {
    clear: both;
    width: 100%;
    font-size: 0;
}

/* RTL support */
.ui-datepicker-rtl {
    direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
    right: 2px;
    left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
    left: 2px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
    right: 1px;
    left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
    left: 1px;
    right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
    clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
    float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
    float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
    border-right-width: 0;
    border-left-width: 1px;
}

/* Icons */
.ui-datepicker .ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
    left: .5em;
    top: .3em;
}

/* Component containers ----------------------------------*/
.ui-widget {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
}

.ui-widget .ui-widget {
    font-size: 1em;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
}

.ui-widget.ui-widget-content {
    border: 0px solid #c5c5c5;
    box-shadow: 0px 0px 54px rgba(0, 0, 0, 0.31);
    padding: 20px;
}

.ui-widget-content {
    border: 1px solid #dddddd;
    background: #ffffff;
    color: #333333;
}

.ui-widget-content a {
    color: #333333;
}

.ui-widget-header {
    /* border: 1px solid #dddddd;
    */
    /* background: #e9e9e9;
    */
    color: #333333;
    font-weight: bold;
}

.ui-widget-header a {
    color: #333333;
}

/* Interaction states ----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
/* We use html here because we need a greater specificity to make sure disabled works properly when clicked or hovered */
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
    border: 0px solid #b4b4b4;
    background: #f6f6f6;
    font-weight: normal;
    color: #4f4f4f;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
    color: #454545;
    text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
    font-weight: normal;
    border: 0px solid #dad55e;
    background: #f6598e;
    color: #ffffff;
    border-radius: 27px;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
    color: #2b2b2b;
    text-decoration: none;
}

.ui-visual-focus {
    box-shadow: 0 0 3px 1px rgb(94, 158, 214);
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
    font-weight: normal;
    border: 0px solid #dad55e;
    background: #1692c0;
    color: #ffffff;
    border-radius: 27px;
}

.ui-icon-background,
.ui-state-active .ui-icon-background {
    border: #003eff;
    background-color: #ffffff;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
    color: #ffffff;
    text-decoration: none;
}

/* Icons ----------------------------------*/
/* states and images */
.ui-icon {
    width: 16px;
    height: 16px;
}

.ui-state-disabled span {
    color: #aaaaaa !important;
}

/*-------------------------------------------------*/
/* = v2.0 MENU 
/*-------------------------------------------------*/
.main-menu-v2 {
    padding: 8px 0px;
    /* border-bottom: 1px solid #efeded;
    */
    background: #253d52;
}

.main-menu-v2 ul {
    position: relative;
    padding: 0px;
    margin-bottom: 0px;
}

.main-menu-v2 ul li {
    float: left;
    list-style-type: none;
    display: inline-block;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.main-menu-v2 ul li a {
    color: #fff;
    text-transform: uppercase;
    padding: 10px 14px;
    line-height: 35px;
    font-weight: 600;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
}

.v2-sub-sinl-men li {
    min-height: 34px !important;
    line-height: 34px !important;
    border-bottom: 1px solid #f3f3f3;
}

.v2-sub-sinl-men li a {
    padding: 7px 12px !important;
}

#main-menu-v2-book {
    float: right;
}

#main-menu-v2-book a {
    font-weight: 700;
    text-decoration: none;
    color: #fff;
    padding: 0px 15px;
    border-radius: 2px;
    display: inline-block;
    background: #1aa5d8;
    background: linear-gradient(to bottom, #1aa5d8, #1386b1);
    font-family: 'Quicksand', sans-serif;
    font-size: 13px;
}

.menu-arr:after {
    content: "\f107";
    font-family: FontAwesome;
    /* background: #ffffff;
    */
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 0;
    right: 10px;
    font-weight: 500;
    padding-left: 4px;
}

.drop-v2-all {
    width: 100%;
    left: 0px !important;
}

.drop-v2-all-inn {
    padding: 20px;
    position: relative;
    overflow: hidden;
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat right bottom;
    background-size: 600px;
}

.menu-sub-drop {
    float: left;
    width: 20%;
}

.menu-sub-drop h4 {
    margin-top: 10px;
    text-transform: uppercase;
    color: #4d90fe;
    font-size: 17px;
    border-bottom: 1px solid #e4e4e4;
    margin-right: 15px;
    padding-bottom: 15px;
}

.menu-sub-drop ul li {
    min-height: 42px;
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
}

.menu-sub-drop ul li a {
    padding: 0px;
    line-height: 26px;
    font-size: 14px;
}

.v2_head_right {
    width: 190px;
}

.v2_head_right li {
    display: block !important;
}

/*-------------------------------------------------------*/
/* SOCIAL SHARE 
/*-------------------------------------------------------*/
.post-btn {
    margin-top: 5px;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
}

.post-btn ul {
    padding: 0px;
    margin-bottom: 0px;
}

.post-btn ul li {
    display: inline-block;
    margin-right: 4px;
    line-height: 40px;
}

.post-btn ul li a {
    text-transform: uppercase;
    color: #fff;
    font-weight: 400;
    font-size: 11.5px;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
    border-radius: 3px;
}

.post-btn ul li:nth-child(1) a {
    background: #3b5998;
}

.post-btn ul li:nth-child(2) a {
    background: #00aced;
}

.post-btn ul li:nth-child(3) a {
    background: #d34836;
}

.post-btn ul li i {
    padding-right: 5px;
}

.contact__form input[type="submit"] {
    width: 100%;
}

.faq-form ul li:nth-child(5) {
    width: 100%;
}

.faq-form ul li:nth-child(6) {
    width: 100%;
}

.faq-form ul li:nth-child(6) textarea {
    width: 100%;
    height: 120px;
    padding: 10px;
    background: #fff;
    resize: none;
    border-radius: 3px;
}

.alert-success {
    text-align: center;
    font-size: 18px;
}

/*--======================================================================--*/
.wed-logo {
    float: left;
    width: 20%;
}

.wed-logo a img {
    width: 250px;
    padding: 7px 0px;
}

.wed-menu {
    float: right;
    padding-top: 14px;
}

.wed-menu ul {
    margin-bottom: 0px;
    /* padding-top: 23px;
    */
}

.wed-menu ul li {
    float: left;
    display: inline-block;
}

.wed-menu ul li:nth-child(2) {
    padding-right: 10px;
}

.wed-menu ul li a {
    color: #363a3c;
    padding: 8px 10px 5px 10px;
}

.wed-search {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat;
    position: relative;
    background-size: cover;
}

.web-search-form input {
    background: #fff !important;
    border: 0px !important;
    height: 45px !important;
    border-radius: 2px !important;
    padding: 0px 10px !important;
    box-sizing: border-box !important;
    font-size: 14px !important;
}

.web-search-form input[type="submit"] {
    background: none !important;
}

.wed-search-1 {
    padding: 250px 0px 100px 0px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
    position: relative;
}

.wed-search-1 h1 {
    color: #fff;
    font-size: 40px;
}

.wed-search-1 p {
    font-size: 18px;
    color: #fff;
    padding-bottom: 15px;
}

.web-search-form label {
    top: 16px;
    left: 18px;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
}

.wed-search:before {
    content: '';
    background: #141E30;
    background: linear-gradient(to right, rgba(36, 59, 85, 0.55), rgba(20, 30, 48, 0.82));
    background: linear-gradient(to right, rgba(36, 59, 85, 0.55), rgba(20, 30, 48, 0.82));
    position: absolute;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
}

.web-search-form div {
    padding-left: 5px;
    padding-right: 5px;
}

.wed-sear-btn {
    height: 45px;
    line-height: 45px;
    background-color: #994b97;
    background: linear-gradient(to bottom, #9a4c98, #6a3179);
    outline: none;
}

.wed-top-phone {
    border: 1px solid #6b6a6a;
    border-radius: 50px;
}

/*-------------------------------------------------*/
/* = 2.MAIN MENU 
/*-------------------------------------------------*/
.main-menu {
    float: right;
    width: 80%;
}

.main-menu ul {
    float: right;
    margin-bottom: 0px;
}

.main-menu ul li {
    float: left;
    display: inline-block;
}

.main-menu ul li a {
    color: #000000;
    padding: 20px 10px;
    line-height: 58px;
    font-size: 15px;
    font-weight: 600;
    /* text-transform: uppercase;
    */
    position: relative;
    font-family: 'Quicksand', sans-serif;
}

.main-menu ul li a:hover {
    color: #f4364f;
}

.main-menu ul li a:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 12px;
    left: 0;
    color: #f4364f;
    background-color: #f4364f;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: all .15s ease-in;
    z-index: 1;
}

.main-menu ul li a:hover:after {
    visibility: visible;
    transform: scaleX(1);
    transition: all .25s ease-out;
}

.main-menu ul li:last-child a {
    padding-right: 0px;
}

.ed-sub-menu:after {
    content: "\f107";
    font-family: FontAwesome;
    padding: 5px 5px;
    position: relative;
    top: 0px;
    font-size: 14px;
    font-weight: 900;
}

.ed-sub-drop-menu:after {
    content: "\f105";
    font-family: FontAwesome;
    padding: 5px 5px;
    position: relative;
    top: -5px;
    font-size: 14px;
    font-weight: 900;
    float: right;
}

.menu-about:after {
    display: none;
}

.mm1-s2 a:after {
    display: none;
}

.mm2-com a:after {
    display: none;
}

.slider {
    height: 500px !important;
}

.slider:after {
    position: absolute;
    content: '';
    background: #4d90fe;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
}

.caption h3 {
    font-size: 60px;
}

.caption h5 {
    font-size: 32px;
}

/*-------------------------------------------------*/
/* = 7.SEARCH BOX 
/*-------------------------------------------------*/
.search-top {
    background: #002147;
    padding: 12px 0px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32);
    /* z-index: 99;
    */
    /* position: absolute;
    */
    width: 100%;
    border-top: 1px solid #dedede;
}

.search-form {
    width: 60%;
    margin: 0 auto;
}

.sf-type {
    float: left;
    width: 75%;
}

.sf-input input {
    width: 100%;
    border: 0px;
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat left center #ffffff;
    background-size: 17px;
    background-position-x: 12px;
    color: #464646;
    font-weight: 500;
    text-transform: capitalize;
    padding: 8px;
    border: 1px solid #ffffff;
    padding: 8px 45px;
    margin: 0px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: 14px;
}

.sf-list {
    display: none;
    position: absolute;
    width: 43.8%;
    background: #fff;
    z-index: 99;
    box-shadow: 0 26px 64px 0 rgba(0, 0, 0, .6);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 300px;
    overflow-y: hidden;
}

.sf-list:hover {
    overflow-y: auto;
}


.sf-list ul li a {
    display: block;
    color: #2b333a;
    padding: 10px;
    border-bottom: 1px solid #efefef;
    font-size: 14px;
    font-weight: 600;
}

.sf-list ul li a:hover {
    background: #eee;
}

.sf-list ul li:last-child a {
    border-bottom: 0px solid #efefef;
}

.sf-submit {
    float: left;
    width: 25%;
}

.sf-submit input {
    width: 100%;
    border: 0px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    padding: 13px;
    border: 1px solid #e25f30;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background: #e05a2b;
    background: -webkit-linear-gradient(to top, #ef612f, #f36b3b);
    background: linear-gradient(to top, #de592a, #f36b3b);
}

/*-------------------------------------------------*/
/* = 8.SLIDER 
/*-------------------------------------------------*/
.slider-arr {
    color: #fff;
    position: absolute;
    font-size: 18px;
    top: 50%;
    width: 50px;
    height: 50px;
    background: #000000;
    padding: 17px;
    border-radius: 50px;
}

.slider-con h2 {
    font-size: 50px;
    text-transform: uppercase;
    line-height: 50px;
}

.slider-con h2 span {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 28px;
    color: #e66030;
}

.slider-con p {
    font-size: 20px;
    color: #fff;
    margin-bottom: 40px;
    margin-top: 30px;
    line-height: 30px;
    font-weight: 300;
}

.slider-con a {
    /* color: #fff;
    */
    padding: 14px 45px;
    line-height: 45px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    height: 45px;
    text-align: center;
    margin: 0px 10px;
}

.bann-btn-1 {
    background: #f36b3b;
    background: -webkit-linear-gradient(to top, #ef612f, #f36b3b);
    background: linear-gradient(to top, #ef612f, #f36b3b);
    color: #fff;
    border: 1px solid #f36b3b;
}

.bann-btn-1:hover {
    border: 1px solid #fff;
    background: #fff;
    color: #4d90fe;
}

.bann-btn-2 {
    border: 1px solid #e0e0e0;
    color: #e0e0e0;
}

.bann-btn-2:hover {
    border: 1px solid #fff;
    background: #fff;
    color: #4d90fe;
}

.item img {
    width: 100%;
}

.top-revi {
    float: left;
    position: relative;
    overflow: hidden;
    padding: 5px 5px 5px 5px;
    width: 20%;
}

.top-revi img {
    width: 50px;
    height: 50px;
    float: left;
    margin-right: 10px;
    border-radius: 5px;
}

.top-revi h4 {
    float: left;
    display: block;
}

.top-revi p {
    /* line-height: 5px;
    */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    margin-bottom: 0px;
    font-size: 12px;
    width: 70%;
}

.top-star-rat {
    margin-bottom: 0px;
    line-height: 10px;
    float: left;
    display: block;
}

.top-star-rat i {
    font-size: 10px;
}

.top-logo {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 1s ease;
}

.pop-form input {
    font-size: 16px;
}

.ff3 {
    border: 1px solid #c3c3c3;
    padding: 25px;
    border-top: 0px;
}

/*-------------------------------------------------*/
/* = 9.HOME PAGE: QUICK LINK 
/*-------------------------------------------------*/
.wed-hom-ser {
    /* position: absolute;
    */
    /* overflow: hidden;
    */
    /* width: 100%;
    */
    /* padding-top: 14px;
    */
    margin-top: -140px;
}

.wed-hom-ser ul {
    margin-bottom: 0px;
    margin: 0 auto;
    display: table;
    /* background: #002147;
    */
    position: relative;
    /* padding: 5px 5px 0px 5px;
    */
    /* border-radius: 5px;
    */
    /* box-shadow: 0 0 5px 0 #002147;
    */
}

.wed-hom-ser ul li {
    float: left;
    display: inline;
    text-align: center;
    margin: 5px;
}

.wed-hom-ser ul li a {
    background: #202f40;
    width: 90px;
    height: 90px;
    display: block;
    padding: 18px 2px 10px 2px;
    text-align: center;
    color: #fff;
    border-radius: 70px;
    font-family: 'Arimo', sans-serif;
    position: relative;
    overflow: hidden;
    line-height: 24px;
    font-size: 11px;
}

.wed-hom-ser ul li a:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    background: #ef6331;
    color: #fff;
}

.wed-hom-ser ul li a img {
    display: table;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 5px;
}

.wed-hom-ser ul li a h4 {
    font-size: 32px;
    color: #002147;
    font-weight: 600;
    margin-bottom: 5px;
}

.wed-hom-ser ul li a span {
    color: #898d92;
    font-size: 12px;
    letter-spacing: 2px;
}

/*-------------------------------------------------*/
/* = 16.REGISTER LOGIN POPUPS 
/*-------------------------------------------------*/
.log-in-pop {
    position: relative;
    overflow: hidden;
    /* height: 100%;
    */
    /* bottom: 0px;
    */
    background: #fff;
    width: 60%;
    margin: 0 auto;
    margin-top: 5%;
}

.log-in-pop-left {
    float: left;
    width: 40%;
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) #002147 no-repeat center center;
    padding: 12.5% 6%;
    color: #fff;
    height: 100%;
    bottom: 0px;
    /* min-height: 115%;
    */
}

.log-in-pop-left h1 {
    color: #fff;
    font-size: 32px;
}

.log-in-pop-left h1 span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    color: #fff;
    font-size: 24px;
}

.log-in-pop-left p {
    color: #fff;
}

.log-in-pop-left h4 {
    color: #fff;
    margin-bottom: 15px;
    margin-top: 15px;
    border-top: 1px solid #002e63;
    padding-top: 15px;
}

.log-in-pop-left ul {
    margin-bottom: 0px;
}

.log-in-pop-left ul li {
    margin-bottom: 5px;
}

.log-in-pop-left ul li a {
    display: block;
    background: #dc2039;
    color: #fff;
    padding: 12px;
    border-radius: 2px;
    font-weight: 600;
    font-size: 14px;
}

.log-in-pop-left ul li:nth-child(1) a {
    background: #39579A;
}

.log-in-pop-left ul li:nth-child(2) a {
    background: #F24033;
}

.log-in-pop-left ul li:nth-child(3) a {
    background: #24A9E6;
}

.log-in-pop-left ul li a i {
    padding-right: 7px;
}

.log-in-pop-right {
    float: left;
    width: 60%;
    padding: 50px;
}

.log-in-pop-right h4 {
    font-size: 24px;
    margin-bottom: 20px;
}

.log-in-pop-right a {
    color: #4d90fe;
}

.log-in-pop-right form label {
    font-size: 14px !important;
    font-weight: 200;
    left: 15px;
    top: 14px;
}

.log-in-pop-right form input {
    border: 1px solid #dfdfdf;
    padding: 8px;
    box-sizing: border-box;
    height: 45px;
    border-radius: 2px;
    font-size: 14px;
    color: #000;
    margin-bottom: 5px;
}

.log-in-pop-right form textarea {
    border: 1px solid #dfdfdf;
    padding: 8px;
    box-sizing: border-box;
    height: 70px;
    border-radius: 2px;
}

.log-in-pop-right form input[type="submit"] {
    color: #fff;
    height: 32px;
}

.padd-top {
    margin-top: 15px;
}

.log-in-btn {
    background: #f4364f;
    color: #fff;
    padding: 2px 10px;
    font-weight: 600;
}

.pop-close {
    color: #4d90fe;
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    top: 15px;
    right: 15px;
}

.pop-close:hover {
    transform: rotate(180deg);
}

.pop-close img {
    width: 24px;
}

.modal-open {
    overflow: inherit !important;
}

.log-ch-bx p label {
    left: 1px;
    top: 10px;
    color: #727070;
}

/*-------------------------------------------------*/
/* = 17.REGISTER LOGIN POPUPS 
/*-------------------------------------------------*/
.in2-top-logo {
    background: #002147;
}

.in2-search-top {
    background: #013673;
    border-top: 1px solid #002147;
}

.in2-main-menu ul li a {
    color: #fff;
}

.in2-dropdown-content {
    background-color: #002147;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.37);
}

.in2-dropdown-content li a {
    border-bottom: 1px solid #032954;
}

.in2-dropdown-content li a:hover {
    background: #013673;
}

.in2-ban {
    margin-top: 72px;
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat center center #4d90fe;
    padding: 80px 0px;
    background-size: cover;
    position: relative;
}

.in2-ban:before {
    content: '';
    position: absolute;
    background: linear-gradient(to top, rgba(2, 35, 74, 0.95) 14%, rgba(0, 33, 71, 0.87) 66%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.in2-bi {
    position: relative;
    overflow: hidden;
    width: 90%;
    margin: 0 auto;
}

.in2-bi-left {
    /* color: #fff;
    */
    width: 70%;
    padding-top: 35px;
}

.in2-bi-left h1 {
    color: #fff;
    text-transform: capitalize;
    font-size: 52px;
}

.in2-bi-left p {
    color: #949494;
    font-size: 18px;
}

.in2-bi-form {
    position: relative;
    overflow: hidden;
    background: #fff;
    box-sizing: border-box;
    padding: 25px;
    border-radius: 4px;
    box-shadow: 0px 16px 40px -10px rgb(0, 0, 0);
    border-bottom: 5px solid #f06433;
}

.in2-bi-form h4 {
    text-align: center;
}

.in2-bi-form input {
    border: 1px solid #e8e8e8;
    height: 45px;
    margin-bottom: 4px;
}

.in2-bi-form label {
    color: #0e0e0e;
    left: 20px;
    top: 15px;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
}

/*-------------------------------------------------*/
/* = 21.MEGA MENU 
/*-------------------------------------------------*/
.mm-pos {
    position: absolute;
    width: 100%;
    right: 0px;
    left: 0px;
}

.about-mm,
.admi-mm,
.cour-mm,
.cr-mm {
    display: none;
}

.m-menu {
    position: absolute;
    overflow: hidden;
    width: 100%;
    box-shadow: 0px 7px 12px -4px rgba(0, 0, 0, 0.45);
    background: url(/whitebg.jpg) no-repeat right bottom, #fff;
    z-index: 999;
    margin-top: -7px;
    padding: 20px 10px;
}

.mm1-com {
    width: 25%;
    float: left;
    box-sizing: border-box;
    padding: 20px;
    border-right: 1px solid #eaeaea;
}

.mm1-com h4 {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
}

.mm1-com ul li {
    display: block;
    width: 100%;
    position: relative;
    padding-left: 15px;
}

.mm1-com ul li a {
    font-size: 14px;
    color: #203245;
    line-height: 31px;
    padding: 5px;
    text-transform: capitalize;
}

.mm1-com ul li a:after {
    bottom: 0px;
}

.mm1-cour-com {
    width: 20%;
}

.ed-dr-men-mar-top {
    padding-top: 30px;
    display: inline-block;
}

.mm1-s1 img {
    width: 100%;
}

.mm1-s2 p {
    font-size: 12px;
    color: #203245;
}

.mm1-s3 ul {
    padding: 0px;
}

.mm1-s4 {
    border-right: 0px solid #eaeaea;
}

.mm1-s4 img {
    width: 100%;
}

.m-menu-inn>.mm1-s2>.mm-r-m-btn {
    background: #002147;
    padding: 4px 10px;
    color: #fff;
    font-size: 11px;
    border-radius: 3px;
    height: 30px;
    line-height: 24px;
}

.mm1-com>.ed-course-in>a {
    padding: 0px;
}

.mm2-com p {
    font-size: 12px;
    color: #203245;
}

.mm2-com>.ed-course-in {
    margin-bottom: 15px;
}

.m-menu-inn>.mm2-com>a {
    background: #002147;
    padding: 4px 10px;
    color: #fff;
    font-size: 11px;
    border-radius: 3px;
    height: 30px;
    line-height: 24px;
}

.m-menu-inn ul li:before {
    content: "\f105";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    /*--adjust as necessary--*/
    color: #000;
    font-size: 16px;
    padding-right: 0.5em;
    position: absolute;
    top: 5px;
    left: 0;
}

.mm1-s4 ul {
    padding: 0px;
}

/*-------------------------------------------------*/
/* = 30.MOBILE MANU 
/*-------------------------------------------------*/
.ed-mob-menu {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 55px;
    background: #fff;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32);
    z-index: 999;
    display: none;
}

.ed-mob-menu-con {
    width: 90%;
    margin: 0 auto;
}

.ed-mm-left {
    float: left;
    width: 50%;
    box-sizing: border-box;
}

.ed-mm-right {
    float: left;
    width: 50%;
    box-sizing: border-box;
}

.ed-micon {
    float: right;
}

.ed-micon i {
    width: 32px;
    height: 32px;
    border: 1px solid #0a2444;
    text-align: center;
    font-size: 20px;
    padding: 5px;
    border-radius: 2px;
    margin-top: 10px;
    color: #0a2444;
    cursor: pointer;
}

.ed-mm-inn {
    position: fixed;
    width: 70%;
    background: #fff;
    padding: 50px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32);
    overflow-y: auto;
    right: -80%;
    height: 100%;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.ed-mm-act {
    right: 0%;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.ed-mi-close {
    position: absolute;
    z-index: 99;
    margin: -50px 8px 8px -30px;
}

.ed-mi-close i {
    width: 32px;
    height: 32px;
    border: 1px solid #0a2444;
    text-align: center;
    font-size: 20px;
    padding: 5px;
    border-radius: 2px;
    margin-top: 10px;
    color: #0a2444;
    cursor: pointer;
}

.ed-mm-inn ul {
    margin-bottom: 30px;
    margin-top: 10px;
    padding: 0px;
}

.ed-mm-inn ul li {
    list-style-type: none;
}

.ed-mm-inn ul li a {
    display: block;
    border-bottom: 1px solid #ececec;
    padding: 6px 0px 6px 25px;
    font-size: 14px;
    color: #42494e;
}

.ed-mm-inn ul li a:before {
    content: "\f105";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    font-size: 14px;
    color: black;
    margin-left: -25px;
}

.affix {
    position: fixed;
    top: 0px;
    width: 100%;
    background: #fff;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.32);
    z-index: 999;
    padding: 1px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sb1 {
    background: #002147;
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 9999;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.48);
}

.sb1 h3 {
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}

.sb1-2 {
    float: right;
}

.sb1-2 button {
    background: #2d3a40;
    border: 0px;
    color: #fff !important;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    padding: 20px;
    border-radius: 0px;
}

.sb2 {
    background: #002147;
    margin-top: 59px;
}

.sb2-1 {
    float: left;
    width: 20%;
    background: #263238;
    color: #fff;
    height: 100%;
    position: absolute;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sb2-1 a {
    color: #cecece;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sb2-1 a:hover {
    color: #fff;
    /* padding-left: 30px;
    */
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    text-decoration: none;
}

.sb2-12 {
    position: relative;
    overflow: hidden;
    padding: 15px;
    background: #e66030;
}

.sb2-12 ul {
    padding: 0px;
}

.sb2-12 ul li {
    float: left;
    display: inline-block;
}

.sb2-12 ul li:nth-child(1) {
    width: 15%;
}

.sb2-12 ul li:nth-child(2) {
    width: 70%;
    padding: 0px 7px;
}

.sb2-12 ul li:nth-child(3) {
    width: 10%;
}

.sb2-12 ul li img {
    width: 30px;
    border-radius: 50%;
}

.sb2-12 ul li h5 {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 14px;
    color: #fff;
}

.sb2-12 ul li h5 span {
    display: block;
    font-size: 12px;
    color: #ffcfbe;
    margin-top: -4px;
}

.sb2-13 {
    background: #002147;
}

.sb2-13 ul {
    /* padding: 20px;
    */
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 0px;
}

.sb2-13 ul li {
    list-style-type: none;
    /* border-bottom: 1px solid #303b40;
    */
    /* padding: 3px 20px;
    */
}

.sb2-13 ul li a {
    display: block;
    font-size: 13.5px;
    padding: 10px 0px;
    border-bottom: 1px solid #002958;
    padding: 12px 20px;
    background: #002147;
}

.sb2-13 ul li a:after {
    font-family: FontAwesome;
    content: "\f105";
    font-size: 16px;
    display: block;
    position: absolute;
    margin-top: -15px;
    right: 20px;
    font-size: 12px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: -webkit-transform .2s ease-in-out;
    -o-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
}

.sb2-13 ul li a:hover:after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sb2-13 ul li a:focus:after {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sb2-13 ul li a:hover {
    background: #022c5d;
}

.sb2-13 ul li a:focus {
    background: #022c5d;
}

.menu-act {
    background: #57666d;
}

.sb2-13 ul li a i {
    margin-right: 8px;
    width: 16px;
}

.sb2-2 {
    float: left;
    width: 80%;
    padding: 40px;
    margin-left: 20%;
    background: #e3e8ea;
}

.sb2-2-1 {
    background: #fff;
    padding: 25px;
}

.sb2-2-1 h2 {
    margin-top: 0px;
}

.sb2-2-1 table {
    width: 100%;
}

.sb2-2-1-edit i {
    color: #FF5722;
}

.sb2-2-add-blog textarea {
    width: 100%;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 200px;
}

.sa-blog-post {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid #d2d2d2;
    padding: 25px 0px;
}

.sa-blog-post h2 {
    color: #000;
    font-weight: 700;
}

.sa-blog-post span {
    font-style: italic;
    color: #888;
}

.sa-blog-post span b {
    font-weight: 500;
    color: #000;
}

.sa-blog-post img {
    width: 100%;
    padding-bottom: 25px;
    padding-top: 20px;
}

.sa-blog-post p {
    color: #888;
    font-size: 15px;
    line-height: 24px;
    padding-bottom: 15px;
}

.sa-blog-post-btn {
    background: #f7941e;
    color: #fff;
    padding: 5px;
}

.sa-blog-com-1 {
    padding: 50px 0px;
}

.sa-blog-rece h3 {
    text-transform: uppercase;
    font-size: 18px;
}

.sa-blog-rece ul {
    padding: 0px;
}

.sa-blog-rece ul li {
    list-style-type: none;
}

.sa-blog-rece ul li a {
    border-bottom: 1px solid #e1e6ec;
    color: #a2a2a2 !important;
    font-weight: 200 !important;
    line-height: 40px;
    padding-bottom: 7px;
    font-size: 14px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sa-blog-rece ul li a:hover {
    padding-left: 8px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.sa-blog-rece ul li a i {
    padding-right: 8px;
}

.sa-blog-g {
    padding-top: 50px;
}

.n-blog-top {
    background: #000000;
    padding: 10px 0px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.8);
}

.blog-logo a img {
    width: 175px;
}

.blog-soc ul {
    padding: 0px;
    float: right;
    margin-bottom: 0px;
    padding-top: 25px;
}

.blog-soc ul li {
    float: left;
    display: inline-block;
    padding-right: 6px;
}

.blog-soc ul li a i {
    color: #ffffff;
    width: 24px;
    height: 24px;
    /* border: 1px solid #6d6d6d;
    */
    padding: 5px;
    text-align: center;
    border-radius: 20px;
    background: #f7941e;
}

.blog-copy {
    padding: 50px;
    background: #000;
    color: #fff;
}

.blog-copy p {
    text-align: center;
    margin-bottom: 0px;
}

.blog-copy p a {
    color: #fff;
}

.blog-login {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
}

.blog-login-in {
    position: relative;
    width: 30%;
    margin: 0 auto;
    margin-top: 7%;
    background: #fff;
    padding: 40px;
    border-radius: 3px;
    box-shadow: 0px 0px 50px 2px rgba(0, 0, 0, 0.51);
}

.blog-login-in form {
    position: relative;
}

.blog-login-in form img {
    margin: 0 auto;
    display: table;
    padding-bottom: 25px;
}

.blog-login-in button {
    color: #fff;
    background-color: #fff;
    border-color: #428433;
    background: #4e923f;
    font-weight: 700;
}

.blog-login-in a {
    display: block;
}

/*-------------------------------------------------------*/
/* 2.LEFT MENU 
/*-------------------------------------------------------*/
.left-sub-menu {
    padding: 0px;
}

.left-sub-menu ul {
    /* padding: 0px 0px 10px 40px;
    */
}

.left-sub-menu ul li {
    list-style-type: none;
    border-bottom: 1px solid #002856;
}

.left-sub-menu ul li:last-child {
    border-bottom: 0px solid #303b40;
}

.left-sub-menu ul li a {
    display: block;
    font-size: 13.5px;
    padding: 10px 24px 10px 45px;
}

.left-sub-menu ul li a:hover {
    background: none;
    padding-left: 55px;
}

.left-sub-menu ul li a:focus {
    background: none;
}

.left-sub-menu ul li a:after {
    display: none;
}

.sb2-2-2 {
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;
}

.sb2-2-2 ul {
    padding: 0px;
    margin-bottom: 0px;
}

.sb2-2-2 ul li {
    list-style-type: none;
    float: left;
    padding-right: 5px;
}

.sb2-2-2 ul li a {
    color: #495d65;
    vertical-align: middle;
}

.sb2-2-2 ul li a i {
    padding-right: 2px;
}

.active-bre:before {
    content: "/";
    padding: 0 5px;
    color: #4d90fe;
}

.my-acc:hover {
    background: #4b5a61 !important;
}

.my-acc:focus {
    background: #4b5a61 !important;
}

/*-------------------------------------------------------*/
/* 7.DROPDOWN 
/*-------------------------------------------------------*/
.drop-down-meta {
    position: absolute;
    right: 30px;
    padding: 3px;
    color: black;
    z-index: 999;
    top: 15px;
    background: #e3e8ea;
    width: 30px;
    height: 30px;
    border-radius: 25px;
}

.drop-down-meta:hover {
    background: #29a0da;
    color: #fff;
}

.box-inn-sp {
    background: #fff;
    /* padding: 25px;
    */
}

/*-------------------------------------------------------*/
/* 8. TAB AND SEARCH 
/*-------------------------------------------------------*/
.tab-inn {
    padding: 25px;
}

.tab-posi {
    position: relative;
    overflow: hidden;
}

.top-menu-sty {
    width: 250px !important;
}

.my-btn {
    color: #ffffff;
    background: #57666d;
    padding: 21px;
    line-height: 18px;
    font-weight: 700;
}

.app-search {
    position: relative;
    margin: 12px 15px 8px 5px;
    font-size: 13px;
    color: #626773;
    padding-left: 20px;
    padding-right: 40px;
    background: transparent;
    border: 1px solid rgba(122, 125, 132, 0.15);
    box-shadow: none;
    border-radius: 30px;
    height: 36px;
    font-weight: 600;
    width: 100%;
}

.app-search input {
    border-bottom: 0px !important;
    /* line-height: 7px !important;
    */
    height: 35px !important;
    color: #cecece;
}

.app-search a {
    position: absolute;
    top: 8px;
    right: 16px;
    color: #7a7d84;
}

.box-second-inn {
    margin-top: 30px;
}

.mar-bot-20 {
    margin-bottom: 20px;
}

.icon-container .icon-preview {
    height: 90px;
    text-align: center;
}

.icon-container span {
    display: block;
}

.icon-container i {
    font-size: 3em;
    margin-bottom: 10px;
}

.tab-pad {
    padding-top: 20px !important;
}

.tab-col {
    padding: 20px !important;
    color: #000;
}

/*-------------------------------------------------------*/
/* 10. TOP BAR 
/*-------------------------------------------------------*/
.btn-noti span {
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -8px;
    height: 14px;
    border-radius: 10px;
    min-width: 15px;
    background: #4CAF50;
    color: #fff;
    line-height: 14px;
    padding: 0px 2px;
    font-size: 11px;
    white-space: nowrap;
    -webkit-transform-origin: -10% center;
    -ms-transform-origin: -10% center;
    transform-origin: -10% center;
    font-family: tahoma;
    box-shadow: 0 0 0 1px #3d793f;
    text-align: center;
}

.top-user-pro {
    padding: 18px 20px;
    color: #fff;
    float: right;
}

.top-user-pro img {
    width: 25px;
    margin-right: 10px;
    border-radius: 35px;
    border: 2px solid #a1adb3;
}

.top-user-pro i {
    margin-left: 5px;
}

.top-not-cen {
    margin: 0 auto;
    display: table;
    padding-top: 5px;
}

.tab-menu {
    display: none;
}

.logo img {
    width: 100%;
}

.atab-menu {
    display: inline-block;
    cursor: pointer;
}

.btn-close-menu {
    display: none;
    z-index: 999;
    background: #0e76a8;
    color: #fff;
    position: absolute;
    left: 240px;
    padding: 5px 8px;
    border-radius: 2px;
    top: 0px;
}

.page-back {
    float: right !important;
}

.page-back a {
    background: #f44336;
    color: #fff !important;
    font-size: 12px;
    padding: 3px 8px;
    border-radius: 25px;
    font-weight: 600;
}

.nav-tabs>li>a {
    color: #0e76a8;
    font-weight: 700;
    font-size: 14px;
}

.nav-tabs>li>a i {
    width: 16px;
    color: #0e76a8;
    margin-right: 5px;
}

.coll-head {
    display: block;
    cursor: pointer;
    min-height: 3rem;
    line-height: 3rem;
    padding: 0 1rem;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.coll-head i {
    width: 2rem;
    font-size: 1.6rem;
    line-height: 3rem;
    display: block;
    float: left;
    text-align: center;
    margin-right: 1rem;
}

.coll-body {
    border-bottom: 1px solid #ddd;
    box-sizing: border-box;
    padding: 2rem;
}

.btn-log-in {
    text-align: center;
}

.for-pass {
    text-align: right;
    padding-top: 15px;
    font-size: 15px;
    font-weight: 700;
    color: #0e76a8;
}

.select-pos {
    position: relative;
    /* overflow: hidden;
    */
    display: initial;
}

.menu-active {
    background: #ffffff !important;
    color: #000 !important;
}

.tab-map iframe {
    width: 100%;
    height: 350px;
    border: 0px;
    display: block;
    pointer-events: none;
    position: relative;
}

.ad-st-view {
    background: #e25e30;
    color: #fff !important;
    font-size: 12px;
    font-weight: 700;
    padding: 1px 5px;
    border-radius: 3px;
}

.admin-form form input {
    border: 1px solid #e2e2e2;
    height: 40px;
    border-radius: 2px;
    font-size: 14px;
    padding-left: 16px;
    box-sizing: border-box;
    margin-bottom: 8px;
}

.admin-form form textarea {
    border: 1px solid #e2e2e2;
    height: 100px;
    border-radius: 2px;
    font-size: 14px;
    padding-left: 16px;
    box-sizing: border-box;
    margin-bottom: 8px;
    padding-top: 15px;
}

.admin-form form label {
    left: 20px;
    font-size: 14px;
    top: 8px;
}

.admin-form form i {
    text-align: center;
    padding: 0px;
}

.admin-form form [type="submit"] {
    width: 100%;
    display: block;
    padding: 1px 30px;
    font-size: 14px;
    font-weight: 700;
}

.ad-cou-deta-h4 h4 {
    margin-top: 15px;
    padding-bottom: 10px;
}

.admin-upload-btn {
    height: 40px !important;
}

.admin-upload-btn span {
    color: #fff;
}

.ad-page-pre-btn {
    background: #03A9F4 !important;
}

.ad-page-pre-btn a {
    color: #fff;
    padding: 0px 10px;
    font-weight: 700;
    font-style: initial;
}

.ad-p-lr {
    padding: 0px 12px;
}

.ad-mar-bot-20 {
    margin-bottom: 20px;
}

/*-------------------------------------------------*/
/* = 2.COLLEGE 
/*-------------------------------------------------*/
.ed-top {
    background: #f5f7f9;
    height: 32px;
}

.ed-com-t1-left ul {
    margin-bottom: 0px;
}

.ed-com-t1-left ul li {
    float: left;
    display: inline-block;
}

.ed-com-t1-left ul li a {
    color: #000;
    padding: 5px 14px;
    line-height: 30px;
    /* border-right: 1px solid #e7ebef; */
    font-size: 12px;
}

.ed-com-t1-right {
    float: right;
}

.ed-com-t1-right ul li {
    float: left;
    display: inline-block;
}

.ed-com-t1-right ul li a {
    color: #fff;
    padding: 7px 12px;
    line-height: 30px;
    font-size: 13px;
}

.ed-com-t1-right ul li:nth-child(1) a {
    background: #f4364f;
    background: linear-gradient(to bottom, #f4364f, #dc2039);
}

.ed-com-t1-right ul li:nth-child(2) a {
    background: #4d90fe !important;
}

/*-------------------------------------------------*/
/* = 6.HOME PAGE: TAB AND ENQUIRY 
/*-------------------------------------------------*/
.ed-com-t1-social {
    position: relative;
    overflow: hidden;
    margin-top: -5px;
    /* float: right;
    */
}

.ed-com-t1-social ul li {
    float: left;
    list-style-type: none;
}

.ed-com-t1-social ul li a {
    line-height: 26px;
}

.ed-com-t1-social ul li a i {
    width: 30px;
    height: 30px;
    /* background: #013673;
    */
    color: #92a5bb;
    padding: 13px 4px 12px 4px;
    text-align: center;
    /* border-right: 1px solid #e7ebef;
    */
}

/*-------------------------------------------------*/
/* = HOME PAGE OFFER 
/*-------------------------------------------------*/
.offer {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.offer:before {
    content: '';
    position: absolute;
    background: linear-gradient(to right, rgba(22, 40, 105, 0.91) 46%, rgba(0, 0, 0, 0.41) 100%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.offer-l {
    padding: 110px 0px 100px 0px;
    text-align: center;
}

.ol-1 {
    width: 250px;
    height: 1px;
    background: #515771;
    /* position: absolute;
    */
    margin: 0 auto;
    display: table;
    /* margin-bottom: 30px;
    */
}

.ol-2 {
    background: #fff;
    padding: 5px 3px 3px 3px;
    position: relative;
    /* top: -18px;
    */
    margin: 0 auto;
    display: table;
    margin-top: -15px;
    border-radius: 2px;
}

.ol-2 i {
    color: #fff;
    font-size: 16px;
    margin: 0px 5px;
}

.ol-3 {
    width: 250px;
    height: 1px;
    background: #515771;
    /* position: absolute;
    */
    margin: 0 auto;
    display: table;
    margin-top: 30px;
    margin-bottom: 30px;
}

.ol-4 {
    font-size: 47px;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    width: 100%;
    display: block;
    line-height: 46px;
    padding-top: 30px;
    text-transform: uppercase;
}

.ol-5 {
    font-size: 66px;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    width: 100%;
    display: block;
    line-height: 46px;
    margin-bottom: 30px;
}

.offer-l ul {
    margin-bottom: 0px;
}

.offer-l ul li {
    display: inline-block;
    margin: 0px 10px;
}

.offer-l ul li span {
    display: block;
    color: #ecdee0;
    font-family: 'Quicksand', sans-serif;
}

.offer-l ul li a {
    border-radius: 70px;
    width: 60px;
    height: 60px;
    vertical-align: sub;
    background: #f4364f;
    /* border: 1px solid #a59d9d;
    */
}

.offer-l ul li a img {
    width: 35px;
    padding: 0px;
    height: 35px;
    margin-left: -8px;
}

.offer-r {
    padding: 50px;
    margin: 0 auto;
    display: table;
}

.or-1 {
    /* font-size: 47px;
    */
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    display: inline-block;
    line-height: 46px;
    padding-top: 30px;
    text-transform: uppercase;
    width: 150px;
    height: 150px;
    background: #ffffff;
    border-radius: 50%;
    padding: 40px;
    text-align: center;
    margin-top: 67px;
    position: absolute;
    margin-left: -100px;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.52);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.or-11 {
    font-size: 45px;
    display: block;
    text-transform: capitalize;
    color: #f4364f;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.or-12 {
    display: block;
    line-height: 20px;
    letter-spacing: 5px;
    border-bottom: 1px solid;
    color: #4d90fe;
    margin-top: 25px;
}

.or-2 {
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    display: inline-block;
    line-height: 46px;
    padding-top: 30px;
    text-transform: uppercase;
    width: 300px;
    height: 300px;
    background: #f4364f;
    border-radius: 50%;
    padding: 50px;
    text-align: center;
    margin-top: 100px;
    position: absolute;
    border: 7px solid #fff;
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.52);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.or-1:hover {
    -ms-transform: rotate(360deg);
    /* IE 9 */
    -webkit-transform: rotate(360deg);
    /* Chrome, Safari, Opera */
    transform: rotate(360deg);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.or-2:hover {
    background: #4d90fe;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.or-2 span {
    display: inline-block;
}

.or-21 {
    font-size: 34px;
    display: block;
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.or-22 {
    font-size: 75px;
    display: block;
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.or-23 {
    font-size: 34px;
    display: block;
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.or-24 {
    font-size: 14px;
    /* display: block;
    */
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    border: 1px solid #fbafb9;
    padding: 6px;
    border-radius: 25px;
}

.or-25 {
    font-size: 16px;
    /* display: block;
    */
    text-transform: capitalize;
    color: #fff;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
}

.slider-wid {
    height: 600px;
}

.slides li img:before {
    content: '';
    position: absolute;
    background: linear-gradient(to right, rgba(88, 25, 39, 0.91) 46%, rgba(0, 0, 0, 0.41) 100%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.slid-cap h2 {
    font-size: 62px;
    color: #fff;
    margin-bottom: 15px;
    margin-top: 0px;
    text-transform: uppercase;
}

.slid-cap h5 {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 16px;
    margin-bottom: 20px;
    letter-spacing: 5px;
}

.slid-cap p {
    margin-bottom: 20px;
    font-size: 18px;
}

.slid-cap a {
    background: #f4364f;
    padding: 7px 25px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Quicksand', sans-serif;
    border-radius: 2px;
    font-size: 14px;
    margin-right: 10px;
}

.hom2-ban-pack {
    padding-top: 0px;
    margin-top: -80px;
    z-index: 9;
    position: relative;
}

.cus-book-form {
    width: 50%;
    margin: 0 auto;
}

.cus-pack-form {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat #fff;
    padding: 100px 0px 45px 0px;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
}

.cus-pack-form:before {
    content: '';
    position: absolute;
    background: linear-gradient(to bottom, rgba(22, 40, 105, 0.91) 46%, rgba(0, 0, 0, 0.41) 100%);
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.cus-title h2 {
    color: #fff;
}

.book-tab {
    background: url(https://baoquocte.vn/stores/news_dataimages/dieulinh/012020/29/15/nhung-buc-anh-dep-tuyet-voi-ve-tinh-ban.jpg) no-repeat #fff;
    padding: 50px 0px 160px 0px;
    background-size: cover;
    position: relative;
    background-attachment: fixed;
}

.book-tit h2 {
    font-size: 42px;
    margin-bottom: 30px;
}

.book-tit h5 {
    font-size: 15px;
    letter-spacing: 4px;
}

.book-tab-inn {
    width: 70%;
    margin: 0 auto;
}

.book-tab-inn h3 {
    margin-top: 0px;
}

.book-tab-body {
    background: #fff;
    border-radius: 0px 0px 10px 10px;
}

.book-tab-tit {
    padding: 50px 50px 0px 50px;
}

.book-tab-form {
    box-shadow: none;
    padding-top: 0px;
    border-bottom: 0px solid #cccccc;
}

.book-tab-inn ul li a {
    color: #555;
    font-weight: 700;
    font-size: 14px;
    background: #fff;
}

.book-tab-inn .nav-tabs>li.active>a,
.book-tab-inn .nav-tabs>li.active>a:hover,
.book-tab-inn .nav-tabs>li.active>a:focus {
    background: #f1334c;
    color: #fff;
    border: 1px solid #f1334c;
}

.course-overlay span {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    z-index: 1;
}

.con-page {
    padding-bottom: 170px;
}

.new-con h2 {
    color: #f26838;
    font-size: 24px;
    font-weight: 700;
}

.new-con h2 span {
    color: #13293D;
    font-size: 24px;
    font-weight: 700;
}

.new-con p a {
    color: #4d90fe;
}

.package-form {
    box-shadow: none;
    padding: 0px;
}

.package-form label,
select,
input {
    font-size: 13px !important;
}

.tour-alp-ri-p3 {
    padding-top: 20px;
}

.trav-ami {
    display: inline-block;
    padding: 15px 15px 5px 15px;
    border-top: 1px solid #dfd2bf;
    width: 100%;
    margin-top: 10px;
}

.trav-ami h4 {
    margin: 0px;
    padding-bottom: 15px;
}

.trav-ami ul {
    display: inline;
}

.trav-ami ul li {
    color: #4d90fe;
    display: block;
    position: relative;
    /* margin: 0 10px 10px 0;
    */
    padding: 11px 10px 1px;
    float: left;
    /* width: 18%;
    */
    text-align: center;
    border: 1px solid #dfd2bf;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 2px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.trav-ami ul li img {
    margin: 0 auto;
    display: table;
    width: 20px;
    margin-bottom: 1px;
}

.trav-ami ul li span {
    font-size: 12px;
}

.trav-list-bod a h3 {
    color: #f4364f;
}

.trav-list-bod p {
    font-size: 13px;
    line-height: 20px;
}

.active,
.dot:hover {
    background-color: transparent;
}

/* Rotate */
.hoverZoom figure img {
    -webkit-transform: rotate(0) scale(1);
    transform: rotate(0) scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.hoverZoom figure:hover img {
    -webkit-transform: rotate(15deg) scale(1.4);
    transform: rotate(15deg) scale(1.4);
}

/* Shine */
.hoverShine figure {
    position: relative;
}

.hoverShine figure::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.hoverShine figure:hover::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

/* Hover show text */
figure.snip0019 {
    font-family: 'Raleway', Arial, sans-serif;
    color: #fff;
    position: relative;
    overflow: hidden;
    width: 100%;
    background: #000000;
    text-align: center;
    border-radius: 5px;

}

figure.snip0019 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

figure.snip0019 img {
    opacity: 1;
    width: 100%;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.snip0019 figcaption {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: left;
}

figure.snip0019 figcaption>div {
    float: left;
    height: 100%;
    overflow: hidden;
    width: 50%;
    position: relative;
}

figure.snip0019 figcaption::before {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 50%;
    width: 2px;
    content: '';
    opacity: 0;
    background-color: rgba(255, 255, 255, 0);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    -webkit-transition-delay: 0.6s;
    transition-delay: 0.6s;
}

figure.snip0019 h5,
figure.snip0019 p {
    margin: 0;
    padding: 20px;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    -webkit-transition: opacity 0.45s, -webkit-transform 0.45s;
    transition: opacity 0.45s, -webkit-transform 0.45s, -moz-transform 0.45s, -o-transform 0.45s, transform 0.45s;
}

figure.snip0019 h5 {
    justify-content: center;
    align-items: center;
    display: flex;
    word-spacing: -0.1em;
    font-weight: 300;
    /* padding-top: 50%; */
    text-transform: uppercase;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-transform: translate3d(50%, 0%, 0);
    transform: translate3d(50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.snip0019 h5 span {
    font-weight: 800;
}

figure.snip0019 p {
    display: flex;
    bottom: 0;
    justify-content: center;
    align-items: center;
    /* padding-top: 50%; */
    font-weight: 300;
    top: 0%;
    color: #000;
    background: #fff;
    -webkit-transform: translate3d(-50%, 0%, 0);
    transform: translate3d(-50%, 0%, 0);
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

figure.snip0019 a {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    color: #fff;
}

figure.snip0019:hover img {
    opacity: 0.7;
}

figure.snip0019:hover figcaption h5,
figure.snip0019:hover figcaption p {
    -webkit-transform: translate3d(0%, 0%, 0);
    transform: translate3d(0%, 0%, 0);
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

figure.snip0019:hover figcaption h5 {
    opacity: 1;
}

figure.snip0019:hover figcaption p {
    opacity: 1;
}

figure.snip0019:hover figcaption::before {
    background: #fff;
    top: 0px;
    bottom: 0px;
    opacity: 1;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
}

.zoomOutTr {
    transition: transform .2s;
}

.zoomOutTr:hover {
    transform: scale(1.1);
}

.Blazing {
    color: #000;
    text-shadow:
        0 3px 20px #ff002f,
        0 0 20px #ff002f,
        0 0 10px red,
        4px -5px 6px #f200ff,
        -4px -10px 10px #f200ff,
        0 -10px 30px #f200ff;
    animation: 1s Blazing infinite alternate linear;
}

@keyframes Blazing {
    0% {
        text-shadow: 0 3px 20px red, 0 0 20px red,
            0 0 10px red,
            0 0 0 #f4364f,
            0 0 5px yellow,
            -2px -5px 5px yellow,
            4px -10px 10px yellow;
    }

    25% {
        text-shadow: 0 3px 20px red, 0 0 30px red,
            0 0 20px red,
            0 0 5px #f4364f,
            -2px -5px 5px yellow,
            3px -10px 10px yellow,
            -4px -15px 20px yellow;
    }

    50% {
        text-shadow: 0 3px 20px red, 0 0 20px red,
            0 -5px 10px red,
            -2px -5px 5px #f4364f,
            3px -10px 10px yellow,
            -4px -15px 20px yellow,
            2px -20px 30px rgba(255, 255, 0, 0.5);
    }

    75% {
        text-shadow: 0 3px 20px red, 0 0 20px red,
            0 -5px 10px red,
            3px -5px 5px #f4364f,
            -4px -10px 10px yellow,
            2px -20px 30px rgba(255, 255, 0, 0.5),
            0px -25px 40px rgba(255, 255, 0, 0)
    }

    100% {
        text-shadow: 0 3px 20px red, 0 0 20px red,
            0 0 10px red,
            0 0 0 #f4364f,
            0 0 5px yellow,
            -2px -5px 5px yellow,
            4px -10px 10px yellow;
    }
}

.form-search,
.col-search,
[class*="col-search"] {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-left: 5px;
}

.btn-maucam {
    color: #fff !important;
    background-color: #f67300 !important;
    border-color: #f67300 !important;
    transition: 0.3s !important;
}

.input-group {
    position: relative !important;
    border-collapse: separate !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
    width: 100% !important;
    flex-flow: row !important;
}

.input-group-text {
    font-size: 1.4rem !important;
}

.input-group-prepend {
    display: flex;
}

.label-search {
    font-weight: 400;
    font-size: 1.4rem;
}

.col-md-12 {
    padding: 0 !important;
}

.sticky-top {
    z-index: 998 !important;
}